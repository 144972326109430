import { useState } from 'react';
import propTypes from 'prop-types';
import { styled } from 'styletron-react';
import useEntity from '@bitsoflove/entity-management/hooks/useEntity';
import { FormControl } from 'baseui/form-control';
import useAdapterHook from '@bitsoflove/entity-management/hooks/useAdapterHook';
import { useGlobalFilterContext } from '../../hooks/useGlobalFilterContext';

const FilterWrapper = styled('div', p => ({
  width: p.width || '100%',
  marginRight: '10px',
  marginBottom: '5px',
}));

const FilterBar = ({
  onChange,
  isCompact,
  adapterHookConfig,
  includeFilters,
  excludeFilters,
}) => {
  const entity = useEntity();
  const hooks = useAdapterHook(entity.key, 'get', adapterHookConfig);
  const { filters: hookFilters } = hooks?.pre({ entity }) || {};

  const globalFilterContext = useGlobalFilterContext();
  const [values, setValues] = useState({
    ...hookFilters,
    ...globalFilterContext,
  });
  const { filters = [] } = entity;

  return (
    <>
      {filters
        .filter(({ key }) =>
          excludeFilters ? !excludeFilters.includes(key) : true,
        )
        .filter(({ key }) =>
          includeFilters ? includeFilters.includes(key) : true,
        )
        .map(field => (
          <FilterWrapper key={field.key} width={field.width}>
            <FormControl
              label={field.label}
              disabled={hookFilters?.[field.key]}
              overrides={{
                ControlContainer: {
                  style: {
                    marginBottom: 0,
                    width: '100%',
                  },
                },
              }}
            >
              <field.Filter
                {...field}
                label={field.name}
                config={field.config}
                value={values?.[field.key]}
                isCompact={isCompact}
                onChange={value => {
                  const newValues = {
                    ...values,
                    [field.key]: value,
                  };

                  setValues(newValues);
                  if (newValues !== null) {
                    onChange(newValues);
                  }
                }}
              />
            </FormControl>
          </FilterWrapper>
        ))}
    </>
  );
};

FilterBar.propTypes = {
  onChange: propTypes.func.isRequired,
  isCompact: propTypes.bool,
  adapterHookConfig: propTypes.object,
  includeFilters: propTypes.arrayOf(propTypes.string),
  excludeFilters: propTypes.arrayOf(propTypes.string),
};

FilterBar.defaultProps = {
  isCompact: false,
  adapterHookConfig: null,
  includeFilters: null,
  excludeFilters: null,
};

export default FilterBar;
