import { useRouter } from 'next/router';
import { bool, elementType, node, shape, string } from 'prop-types';
import React, { useContext, useEffect, useState, useRef } from 'react';
import vest, { enforce, test } from 'vest';

import { ROLES } from '~/config/permissions';
import {
  ACTIVATE_ACCOUNT_MUTATION,
  LOGIN_MUTATION,
  REQUEST_PASSWORD_MUTATION,
  RESET_PASSWORD_MUTATION,
} from '~/queries/authentication';
import { hasAccess } from '~/utils/hasAccess';

import { setClientToken } from './apollo.client';
import {
  authClient,
  clearToken,
  getAccessToken,
  getUserRoles,
  setToken,
} from './auth';
import { setClientToken as setLegacyClientToken } from './client';

const AuthContext = React.createContext();

export const STATE_LOADING = 'loading';
export const STATE_CHECKING = 'checking';
export const STATE_REDIRECTING = 'redirecting';
export const STATE_READY = 'ready';
export const STATE_UNAUTHORIZED = 'unauthorized';

export const ALLOWED_ROLES = ['root', 'admin', 'site admin'];

export function useAuth() {
  return useContext(AuthContext);
}

export const validateLogin = vest.create(
  'login',
  ({ email, password, 'repeat-password': repeat } = {}, fieldName) => {
    vest.only(fieldName);

    test('email', 'E-mail is required', () => {
      enforce(email).isNotEmpty();
    });
    test('email', 'E-mail is invalid', () => {
      if (email) {
        // really really simple validation not suitable for real input, but good enough for logins
        enforce(email).matches(/^.+@[^.]+\..+$/);
      }
    });

    test('password', 'Password is required', () => {
      enforce(password).isNotEmpty();
    });
    test('repeat-password', 'Passwords must match', () => {
      enforce(repeat).equals(password);
    });
  },
);

export default function AuthProvider({
  isAuth,
  isPublic,
  GlobalLoader,
  children,
  routes,
}) {
  const redirect = useRef([routes.authenticated]);
  const { pathname, asPath, replace } = useRouter();
  const [initialized, setInitialized] = useState(false);
  const [state, setState] = useState(STATE_LOADING);
  const [error, setError] = useState(null);
  const [authenticated, setAuthenticated] = useState();
  const [userRoles, setUserRoles] = useState([]);
  const isReady = state === STATE_READY;
  const isUnauthorized = state === STATE_UNAUTHORIZED;
  const showPage = isReady || isAuth || isPublic;
  const showLoader = !isReady && !isUnauthorized && (!isPublic || isAuth);
  const setRedirectTo = value => {
    redirect.current = value;
  };

  const clear = () => {
    if (initialized) {
      setState(STATE_READY);
    }
    setError(null);
  };

  const logout = async () => {
    clearToken();
    setClientToken(null);
    setState(STATE_REDIRECTING);
    setAuthenticated(false);
    setUserRoles([]);
    setRedirectTo([routes.authenticated]);
    await replace(routes.login);
    setState(STATE_READY);
  };

  const execute = async ({ input, mutation }) => {
    setError(null);
    setState(STATE_CHECKING);

    try {
      return await authClient.request(mutation, {
        input,
      });
    } catch (e) {
      console.error('Request failed', e);
      setError(e);
    }
    setState(STATE_READY);
    return null;
  };

  const handleLoginResult = async login => {
    if (login) {
      const roles = login.user.roles.map(({ name }) => name);
      const userHasAccess = hasAccess(Object.values(ROLES), roles);

      if (userHasAccess) {
        // successful login, store the token and redirect
        setToken(login);
        setClientToken(login.accessToken);

        setAuthenticated(true);
        setUserRoles(roles);
        setError(null);

        setState(STATE_REDIRECTING);
        await replace(...redirect.current);
        setState(STATE_READY);
      } else {
        setState(STATE_UNAUTHORIZED);
        setError(STATE_UNAUTHORIZED);
      }
    } else {
      setState(STATE_READY);
    }
  };

  const login = async ({ email, password }) => {
    const input = {
      email,
      password,
    };

    const loginResult = await execute({ input, mutation: LOGIN_MUTATION });
    await handleLoginResult(loginResult?.login);
  };

  const requestPassword = async ({ email }) => {
    const input = {
      email,
    };

    const requestResult =
      (await execute({ input, mutation: REQUEST_PASSWORD_MUTATION }))
        ?.requestPasswordReset || false;

    setState(STATE_READY);
    return requestResult;
  };

  const resetPassword = async ({ token, password }) => {
    const input = {
      token,
      password,
    };

    const resetResult = await execute({
      input,
      mutation: RESET_PASSWORD_MUTATION,
    });
    await handleLoginResult(resetResult?.resetPassword);
  };

  const activateAccount = async ({ token, password }) => {
    const input = {
      token,
      password,
    };

    const activateResult = await execute({
      input,
      mutation: ACTIVATE_ACCOUNT_MUTATION,
    });
    await handleLoginResult(activateResult?.activateAccount);
  };

  // check initial authentication state when arriving on the login page
  useEffect(() => {
    let canceled = false;
    getAccessToken().then(async accessToken => {
      if (!canceled) {
        setAuthenticated(!!accessToken);
        if (accessToken) {
          setUserRoles(getUserRoles);
          setClientToken(accessToken);
          setLegacyClientToken(accessToken);

          if (isAuth) {
            setState(STATE_REDIRECTING);
            await replace(...redirect.current);
          }
        } else if (!isPublic && !isAuth) {
          setState(STATE_REDIRECTING);
          await replace(routes.login);
        }
        setInitialized(true);
        setState(STATE_READY);
      }
    });

    return () => {
      canceled = true;
    };
  }, []);

  useEffect(() => {
    if (!authenticated && !isAuth) {
      setRedirectTo([pathname, asPath]);
    }
  }, [authenticated, isAuth, pathname, asPath]);

  return (
    <AuthContext.Provider
      value={{
        state,
        error,
        login,
        clear,
        requestPassword,
        resetPassword,
        activateAccount,
        logout,
        authenticated,
        userRoles,
      }}
    >
      {/* Yes these wrapping div's are necessary. This prevents some weird edge case with SSR and Styletron. */}
      <div>{showLoader && <GlobalLoader />}</div>
      <div>{showPage && children}</div>
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  /** Define the default routes to redirect to. The `authenticated` route may be overwritten by initial page. */
  routes: shape({
    login: string.isRequired,
    authenticated: string.isRequired,
  }).isRequired,
  /** Is the current page an auth page? (Overrides `isPublic`) */
  isAuth: bool,
  /** Is the current page publicly accessible? */
  isPublic: bool,
  /** Global loader component to render while auth is initialising. */
  GlobalLoader: elementType,
  children: node.isRequired,
};
AuthProvider.defaultProps = {
  isAuth: false,
  isPublic: false,
  GlobalLoader: () => null,
};
