import { Button } from 'baseui/button';
import { ButtonGroup } from 'baseui/button-group';
import { Drawer } from 'baseui/drawer';
import { FormControl } from 'baseui/form-control';
import { Delete, Plus, Search } from 'baseui/icon';
import propTypes, { object } from 'prop-types';
import { useState, cloneElement } from 'react';

import {
  EntityCreateForm,
  EntityPicker,
} from '@bitsoflove/entity-management/containers';

import { defaultFieldDefaultValues, defaultFieldPropTypes } from '../Types';
import EntityPickerImagePreview from './ImagePreview';
import EntityPickerSimpleDisplay from './SimpleDisplay';

const DRAWER_CREATE = 'create';
const DRAWER_FIND = 'find';

const EntityPickerField = ({
  label,
  error,
  value,
  onChange,
  entityConfig,
  display,
  actions,
  overrides,
}) => {
  const [drawer, setDrawer] = useState(false);

  const onChangeInternal = entity => {
    const result = onChange(entity);

    // this allows the implementation to prevent the drawer from closing
    if (result !== false) {
      setDrawer(false);
    }
  };

  const handleDelete = async () => {
    const question = 'Are you sure you want to delete this asset ?';
    if (!confirm(question)) {
      return; // user cancelled
    }

    onChange(null);
  };

  return (
    <FormControl label={label} error={error} overrides={overrides}>
      <ButtonGroup>
        {cloneElement(display, {
          value,
        })}
        <Button type="button" onClick={() => setDrawer(DRAWER_FIND)}>
          <Search />
        </Button>
        <Button type="button" onClick={() => setDrawer(DRAWER_CREATE)}>
          <Plus />
        </Button>
        {value && value.id ? (
          <Button type="button" onClick={handleDelete}>
            <Delete />
          </Button>
        ) : null}
        {actions}
        <Drawer
          isOpen={drawer}
          autoFocus
          onClose={() => setDrawer(false)}
          overrides={{
            DrawerContainer: {
              style: {
                width: '80%',
              },
            },
          }}
        >
          {drawer === DRAWER_CREATE ? (
            <EntityCreateForm {...entityConfig} onSuccess={onChangeInternal} />
          ) : (
            <EntityPicker {...entityConfig} onPick={onChangeInternal} />
          )}
        </Drawer>
      </ButtonGroup>
    </FormControl>
  );
};

EntityPickerField.propTypes = {
  ...defaultFieldPropTypes,
  name: propTypes.string,
  placeholder: propTypes.string,
  display: propTypes.element,
  onChange: propTypes.func.isRequired,
  entityConfig: propTypes.shape({
    entity: object.isRequired,
    defaultValues: object,
    adapterHookConfig: object,
  }).isRequired,
  actions: propTypes.node,
  overrides: propTypes.shape({
    Label: propTypes.object,
    Caption: propTypes.object,
    ControlContainer: propTypes.object,
  }),
};

EntityPickerField.defaultProps = {
  ...defaultFieldDefaultValues,
  display: <EntityPickerSimpleDisplay placeholder="No value selected." />,
  actions: null,
  overrides: undefined,
};

export default EntityPickerField;
export { EntityPickerSimpleDisplay, EntityPickerImagePreview };
