import { useState } from 'react';
import propTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { ButtonGroup } from 'baseui/button-group';
import { colors } from 'baseui/tokens';

import { KIND } from 'baseui/button';
import getLocalStorageKeyForOverviewLabel from '@bitsoflove/entity-management/utils/getLocalStorageKeyForOverviewLabel';
import isTableColumnVisible from '@bitsoflove/entity-management/utils/isTableColumnVisible';
import isNullOrUndefined from '~/utils/isNullOrUndefined';
import useEntity from '../../hooks/useEntity';

function ChooseTableColumnsModal({ isOpen, onClose }) {
  const entity = useEntity(); // or via props (entity definition)

  const [changes, setChanges] = useState({});

  /**
   * Returns the value from local "changed" state, from localStorage, or otherwise defaults to true
   */
  const isOverviewLabelChecked = overviewLabelKey => {
    const fromChanged = changes[overviewLabelKey];
    if (!isNullOrUndefined(fromChanged)) {
      return fromChanged;
    }

    return isTableColumnVisible(entity.key, overviewLabelKey);
  };

  const overviewLabels = entity.overviewLabels.map(overviewLabel => {
    return {
      ...overviewLabel,
      isChecked: isOverviewLabelChecked(overviewLabel.key), // todo get from local storage, default true
    };
  });

  const handleCheckedChanged = (e, overviewLabel) => {
    setChanges({
      ...changes,
      [overviewLabel.key]: e.target.checked,
    });
  };

  const handleSave = () => {
    Object.keys(changes).forEach(overviewLabelKey => {
      const changedValue = changes[overviewLabelKey];
      const lsKey = getLocalStorageKeyForOverviewLabel(
        entity.key,
        overviewLabelKey,
      );
      localStorage.setItem(lsKey, changedValue);

      // we can close the modal now
      onClose();
    });
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalHeader>{entity.name} table columns.</ModalHeader>
      <ModalBody>
        <form>
          <ul
            style={{
              listStyleType: 'none',
              paddingLeft: '0px',
            }}
          >
            {overviewLabels.map(overviewLabel => {
              const isActionable = !!overviewLabel.actionable;
              const forIdentifier = `manage-column-${entity.key.replaceAll(
                ' ',
                '-',
              )}-${overviewLabel.key}`;
              return (
                <li>
                  <label htmlFor={forIdentifier}>
                    <input
                      // we cannot allow toggling the actionable column
                      // otherwise the user would not be able to show it again after hiding it
                      disabled={isActionable}
                      id={forIdentifier}
                      name={forIdentifier}
                      type="checkbox"
                      defaultChecked={overviewLabel.isChecked}
                      onChange={e => handleCheckedChanged(e, overviewLabel)}
                    />
                    &nbsp;{overviewLabel.name}
                  </label>
                </li>
              );
            })}
          </ul>
        </form>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <ModalButton type="button" onClick={onClose}>
            Cancel
          </ModalButton>
          <ModalButton
            type="button"
            style={{
              marginLeft: 'auto',
              backgroundColor: 'green',
              color: colors?.white,
            }}
            overrides={{
              kind: KIND.primary,
            }}
            onClick={handleSave}
          >
            Save
          </ModalButton>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
}

ChooseTableColumnsModal.propTypes = {
  isOpen: propTypes.bool,
  onClose: propTypes.func.isRequired,
};

ChooseTableColumnsModal.defaultProps = {
  isOpen: false,
};

export default ChooseTableColumnsModal;
