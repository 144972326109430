import { StyledSpinnerNext } from 'baseui/spinner';
import propTypes from 'prop-types';

import FormWrapper from '@bitsoflove/entity-management/components/FormWrapper';
import RequestError from '@bitsoflove/entity-management/components/RequestError';
import handleSubmitError from '@bitsoflove/entity-management/utils/handleSubmitError';

import EntityContext from '../../Context/EntityContext';
import useFindAdapter from '../../hooks/useFindAdapter';
import Form from '../internal/Form';

function EntityUpdateForm({
  id,
  entity,
  children,
  onSuccess,
  value: providedValue,
  fieldPayload,
}) {
  const { item, meta, refetch } = useFindAdapter(entity, { id }, providedValue);
  const { loading, error } = meta;

  if (loading || !item) {
    return <StyledSpinnerNext />;
  }

  if (error) {
    return (
      <RequestError
        message="Something went wrong."
        error={error}
        refetch={refetch}
        loading={loading}
      />
    );
  }

  return (
    <FormWrapper>
      <EntityContext.Provider value={entity}>
        <Form
          isUpdate
          initialValues={
            item &&
            entity?.properties
              .filter(({ isReadOnly }) => !isReadOnly)
              .reduce(
                (all, field) => ({ ...all, [field.key]: field.value?.(item) }),
                { id },
              )
          }
          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            try {
              setFieldError('submit', null);
              setSubmitting(true);

              const result = await entity?.adapters?.update({
                values,
              });

              onSuccess(result);
            } catch (e) {
              handleSubmitError({
                fallback: 'Unable to update entity',
                setSubmitting,
                setFieldError,
                error: e,
              });
            }
          }}
          fieldPayload={fieldPayload}
        >
          {children}
        </Form>
      </EntityContext.Provider>
    </FormWrapper>
  );
}

EntityUpdateForm.propTypes = {
  id: propTypes.string.isRequired,
  entity: propTypes.object.isRequired,
  children: propTypes.node,
  onSuccess: propTypes.func.isRequired,
  value: propTypes.object,
  fieldPayload: propTypes.object,
};

EntityUpdateForm.defaultProps = {
  children: null,
  value: null,
  fieldPayload: {},
};

export default EntityUpdateForm;
