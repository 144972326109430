import { Button } from 'baseui/button';
import { StatefulMenu } from 'baseui/menu';
import { StatefulPopover } from 'baseui/popover';
import { useRouter } from 'next/router';
import propTypes from 'prop-types';
import { useState } from 'react';

import ConfirmDeleteModal from '../containers/internal/ConfirmDeleteModal';
import ConfirmDuplicateModal from '../containers/internal/ConfirmDuplicateModal';
import useEntity from '../hooks/useEntity';
import hasAdapterAccess from '../utils/hasAdapterAccess';

function OverviewActions({ item, onUpdate, onDelete }) {
  const entity = useEntity();
  const router = useRouter();

  const onDuplicate = duplicateItem => {
    router.push(
      `/entities/[type]/[id]`,
      `/entities/${entity.key}/${duplicateItem?.id}`,
    );
  };

  const { overviewActions = [] } = entity;
  const shouldShowUpdateAction =
    entity?.adapters?.update && hasAdapterAccess(entity, 'update');
  const shouldShowDeleteAction =
    entity?.adapters?.delete && hasAdapterAccess(entity, 'delete');

  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const getDuplicateAction = () => {
    return {
      label: 'Duplicate',
      onItemSelect: () => setIsDuplicateModalOpen(true),
    };
  };

  const getAction = actionContainer => {
    const { action } = actionContainer;

    switch (action) {
      case 'duplicate':
        return getDuplicateAction();
      default:
        return {
          label: action,
          onItemSelect: () => {},
        };
    }
  };

  const updateAction = {
    label: 'Edit',
    onItemSelect: () => onUpdate(item),
  };

  const deleteAction = {
    label: 'Delete',
    onItemSelect: () => setIsDeleteModalOpen(true),
  };

  const actions = [
    shouldShowUpdateAction ? updateAction : null,
    ...overviewActions.map(actionContainer => getAction(actionContainer)),
    shouldShowDeleteAction ? deleteAction : null,
  ].filter(i => !!i);

  return (
    <>
      <StatefulPopover
        content={({ close }) => (
          <StatefulMenu
            items={actions}
            onItemSelect={({ item: action }) => {
              close();
              action.onItemSelect();
            }}
          />
        )}
        returnFocus
        autoFocus
        placement="rightTop"
      >
        <Button size="mini">
          &nbsp;
          <i className="icon-next" />
        </Button>
      </StatefulPopover>

      <ConfirmDuplicateModal
        item={item}
        isOpen={isDuplicateModalOpen}
        onClose={() => setIsDuplicateModalOpen(false)}
        onComplete={duplicationResult => {
          setIsDuplicateModalOpen(false);
          onDuplicate(duplicationResult);
        }}
      />

      <ConfirmDeleteModal
        item={item}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onComplete={() => {
          setIsDeleteModalOpen(false);
          onDelete();
        }}
      />
    </>
  );
}

OverviewActions.propTypes = {
  item: propTypes.object.isRequired,
  onUpdate: propTypes.func.isRequired,
  onDelete: propTypes.func.isRequired,
};

OverviewActions.defaultProps = {};

export default OverviewActions;
