import { getUserRoles } from '~/api/auth';
import { ROLES } from '~/config/permissions';
import { hasAccess } from '~/utils/hasAccess';

export function assertCanViewContentEntity() {
  return hasAccess(
    [ROLES.SITE_ADMIN, ROLES.CONTENT_TEAM, ROLES.SUPPORT],
    getUserRoles(),
  );
}

export function assertCanCreateContentEntity() {
  return hasAccess([ROLES.CONTENT_TEAM, ROLES.SUPPORT], getUserRoles());
}

export function assertCanUpdateContentEntity() {
  return hasAccess([ROLES.CONTENT_TEAM, ROLES.SUPPORT], getUserRoles());
}

export function assertCanDeleteContentEntity() {
  return hasAccess([], getUserRoles());
}

export function assertCanDuplicateContentEntity() {
  return hasAccess(
    [ROLES.SITE_ADMIN, ROLES.CONTENT_TEAM, ROLES.SUPPORT],
    getUserRoles(),
  );
}
