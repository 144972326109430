const getFilenameFromPath = src => {
  if (typeof src !== 'string') {
    return '';
  }

  const split = src.split('/');
  const filename = split[split.length - 1];

  // clear querystring that might still be there
  const filenameSplit = filename.split('?');
  return filenameSplit[0];
};

export default getFilenameFromPath;
