import { useContext } from 'react';
import { TagFilterContext } from '~/config/entities/utils/TagFilterConfig';
import { SearchFilterContext } from '~/config/entities/utils/textSearchFilterConfig';

export const useGlobalFilterContext = () => {
  const [initialTagFilterValue] = useContext(TagFilterContext);
  const [initialTextSearchValue] = useContext(SearchFilterContext);

  return {
    tag: initialTagFilterValue,
    query: initialTextSearchValue,
  };
};
