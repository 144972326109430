import { gql } from 'graphql-request';
import vest, { enforce, test } from 'vest';

import ColorBlock from '@bitsoflove/entity-management/components/ColorBlock';
import { Text, Color, NestedField } from '@bitsoflove/entity-management/fields';
import { createEntity, testNested } from '@bitsoflove/entity-management/utils';
import client from '~/api/client';

import locales, { nestedLocalesDefaultValue } from '../locales';
import UserInformationColumnLabels from './columns/UserInformationColumnLabels';
import UserInformationColumnValues from './columns/UserInformationColumnValues';
import {
  assertCanCreateContentEntity,
  assertCanDeleteContentEntity,
  assertCanUpdateContentEntity,
  assertCanViewContentEntity,
} from './utils/assertAccess';
import getBoldItemName from './utils/getBoldItemName';
import textSearchFilterConfig from './utils/textSearchFilterConfig';
import transformAPITranslations from './utils/transformAPITranslations';
import transformFormTranslations from './utils/transformFormTranslations';

const GET_QUERY = gql`
  query($page: Int!, $count: Int, $filters: FilterInput!) {
    cms {
      tags(page: $page, count: $count, filters: $filters) {
        total
        perPage
        currentPage
        lastPage
        items {
          id
          slug
          name
          meta
          createdAt
          updatedAt
          createdBy {
            firstName
            lastName
          }
          updatedBy {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const FIND_QUERY = gql`
  query($id: String!) {
    cms {
      tag(id: $id) {
        id
        slug
        name
        meta
        translations {
          locale
          name
        }
      }
    }
  }
`;

const CREATE_MUTATION = gql`
  mutation createTag($input: TagInput!) {
    cms {
      createTag(input: $input) {
        id
        slug
        name
        meta
        translations {
          locale
          name
        }
      }
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation updateTag($input: TagInput!) {
    cms {
      updateTag(input: $input) {
        id
        slug
        name
        meta
        translations {
          locale
          name
        }
      }
    }
  }
`;

export const PRE_DELETE_QUERY = gql`
  query tagUsage($id: String!) {
    cms {
      tagUsage(id: $id)
    }
  }
`;

const DELETE_MUTATION = gql`
  mutation deleteTag($id: String!) {
    cms {
      deleteTag(id: $id)
    }
  }
`;

export default createEntity({
  key: 'tag',
  name: 'Tag',
  className: 'BitsOfLove\\Taxonomy\\Models\\Tag',
  getItemTitle: item => item.name,
  getHumanReadableName: item => item.name,
  filters: [textSearchFilterConfig],
  overviewLabels: [
    {
      key: 'actions',
      name: 'Actions',
      actionable: true,
      width: 120,
    },
    {
      key: 'name',
      name: 'Name',
      sortable: true,
    },
    'Color',
    ...UserInformationColumnLabels,
  ],
  overviewValues: [
    null,
    getBoldItemName,
    item => <ColorBlock color={item.meta?.color} />,
    ...UserInformationColumnValues,
  ],
  properties: [
    {
      key: 'slug',
      name: 'Slug',
      value: item => item.slug,
      Field: Text,
    },
    {
      key: 'color',
      name: 'Color',
      value: item => item.meta?.color,
      overviewValue: item => <ColorBlock color={item.meta?.color} />,
      Field: Color,
    },
    {
      key: 'translations',
      name: 'Translations',
      value: item => item.translations,
      initialValue: nestedLocalesDefaultValue,
      Field: NestedField,
      data: {
        pivotKey: 'locale',
        pivotValues: locales,
        properties: [
          {
            key: 'name',
            name: 'Name',
            value: item => item.name,
            Field: Text,
          },
        ],
      },
    },
  ],
  validate: vest.create('parameter', (data = {}, fieldName) => {
    vest.only(fieldName);

    test('slug', 'Slug is required', () => {
      enforce(data.slug).isNotEmpty();
    });

    testNested('translations', data.translations, (scopedTest, translation) => {
      scopedTest('name', 'Name is required', () => {
        enforce(translation.name).isNotEmpty();
      });

      scopedTest(
        'name',
        'Name should be shorter or equal to 255 characters',
        () => {
          enforce(translation.name).shorterThanOrEquals(255);
        },
      );
    });

    test('color', 'Color is required', () => {
      enforce(data.color).isNotEmpty();
    });
  }),
  hasAdapterAccess: {
    get: assertCanViewContentEntity,
    find: assertCanViewContentEntity,
    create: assertCanCreateContentEntity,
    update: assertCanUpdateContentEntity,
    delete: assertCanDeleteContentEntity,
    preDelete: assertCanDeleteContentEntity,
  },
  adapters: {
    get: async ({ page = 1, count, filters }) => {
      const result = await client.request(GET_QUERY, {
        page,
        count,
        filters: {
          query: filters?.query,
          orderBy: filters?.orderBy,
          orderDirection: filters?.orderDirection,
        },
      });
      const data = result?.cms?.tags;
      return [
        data?.items.map(transformAPITranslations),
        {
          total: data?.total,
          count: data?.perPage,
          currentPage: data?.currentPage,
          lastPage: data?.lastPage,
          pageProps: nextPage => ({
            page: nextPage,
          }),
          nextProps:
            page < data?.lastPage &&
            (() => ({
              page: page + 1,
            })),
          previousProps:
            page > 1 &&
            (() => ({
              page: page - 1,
            })),
        },
      ];
    },
    find: async ({ id }) => {
      const result = await client.request(FIND_QUERY, { id });
      return transformAPITranslations(result?.cms?.tag);
    },
    create: async ({ values }) => {
      const result = await client.request(CREATE_MUTATION, {
        input: {
          id: values.id,
          slug: values.slug,
          meta: {
            color: values.color,
          },
          translations: transformFormTranslations(values),
        },
      });
      return transformAPITranslations(result?.cms?.createTag);
    },
    update: async ({ values }) => {
      const result = await client.request(UPDATE_MUTATION, {
        input: {
          id: values.id,
          slug: values.slug,
          meta: {
            color: values.color,
          },
          translations: transformFormTranslations(values),
        },
      });
      return transformAPITranslations(result?.cms?.updateTag);
    },
    delete: async ({ id }) => {
      const result = await client.request(DELETE_MUTATION, { id });
      return result?.cms?.deleteTag;
    },
    preDelete: async ({ id }) => {
      const result = await client.request(PRE_DELETE_QUERY, {
        id,
      });
      return result?.cms?.tagUsage;
    },
  },
});
