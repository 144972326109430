import info from '@bitsoflove/console-info';
import getConfig from 'next/config';

const { publicRuntimeConfig = {} } = getConfig() || {};

if (process.browser && publicRuntimeConfig.BUILD_INFO) {
  info(publicRuntimeConfig.BUILD_INFO);
}

export const {
  STATIC_DIR,
  BASE_URL,
  BRANCH,
  ENVIRONMENT,

  // Custom project environment variables
  GTM_TRACKING_ID,
  API_URL,
  PRODUCTION_URL,
} = publicRuntimeConfig;
