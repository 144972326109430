import { FormControl } from 'baseui/form-control';
import { Select } from 'baseui/select';
import { useFormikContext } from 'formik';
import propTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'react-use';

import useGetAdapter from '@bitsoflove/entity-management/hooks/useGetAdapter';
import slugify from '@bitsoflove/entity-management/utils/slugify';
import patchValuesIntoItems from '~/utils/patchValuesIntoItems';

import ErrorMessage from '../ErrorMessage';
import { VirtualDropdown } from '../Select/FixedSize';
import { defaultFieldPropTypes, defaultFieldDefaultValues } from '../Types';
import Creatable from './Creatable';

const formatValue = value => {
  if (typeof value === 'string') {
    return [{ id: value }];
  }
  if (Array.isArray(value)) {
    return value;
  }
  return [value];
};

function DisabledSelectField({ name, error, value, data, ...props }) {
  const {
    source,
    transformItem,
    getLabel = null,
    adapterHookConfig,
    ...selectProps
  } = data;
  const items = value && [transformItem?.(value) || value];

  return (
    <Select
      placeholder="No value"
      {...props}
      {...selectProps}
      name={name}
      disabled
      options={items}
      value={value && formatValue(value)}
      getOptionLabel={getLabel}
      getValueLabel={getLabel}
      filterOptions={null}
      error={error}
      overrides={{
        ...selectProps.overrides,
        Dropdown: VirtualDropdown,
      }}
    />
  );
}
function EnabledSelectField({
  name,
  error,
  onChange,
  options,
  value,
  data,
  disabled,
  ...props
}) {
  const [isCreating, setIsCreating] = useState(null);
  const [isFetchingItems, setIsFetchingItems] = useState(null);
  const controlRef = useRef(null);

  const formik = useFormikContext();
  const {
    source,
    transformItem,
    getLabel = null,
    creatable = false,
    adapterHookConfig,
    ...selectProps
  } = data;

  const { items: unpatchedItems = [], meta, refetch } = useGetAdapter(source, {
    adapterHookConfig,
  });
  const items = patchValuesIntoItems(unpatchedItems, value, !!data.multi);

  const [hasFocus, setHasFocus] = useState(null);
  const [debouncedValue, setDebouncedValue] = useState(null);
  const { loading } = meta;

  const mappedOptions = loading ? [] : items?.map(transformItem || (i => i));
  const itemsEffectKey = mappedOptions.map(option => option.id).join('|');

  const debounceTimeout = 500;
  const [, cancelDebounce] = useDebounce(
    () => {
      if (debouncedValue !== null) {
        setIsFetchingItems(true);
        refetch({ filters: { query: debouncedValue } });
      }
    },
    debounceTimeout,
    [debouncedValue],
  );

  useEffect(() => cancelDebounce(), [cancelDebounce]);

  useEffect(() => {
    const shouldReset =
      hasFocus && controlRef.current && isFetchingItems !== null;

    if (shouldReset) {
      controlRef.current.focus();
      setIsFetchingItems(false);
    }
  }, [hasFocus, isFetchingItems, itemsEffectKey]);

  /** On focus out, clear selected value and refetch default options (reset state) */
  const handleBlur = () => {
    setDebouncedValue('');

    formik.setFieldTouched(name, true);
    setHasFocus(false);
  };

  const handleFocus = () => {
    setHasFocus(true);
  };

  return (
    <>
      {isCreating && (
        <Creatable
          entity={source}
          values={{
            slug: slugify(isCreating.label),
            translations: {
              en: {
                name: isCreating.label,
              },
            },
          }}
          onCancel={() => setIsCreating(null)}
          onCreate={item => {
            onChange([...value, transformItem(item)]);
            setIsCreating(null);
          }}
        />
      )}
      <Select
        {...props}
        {...selectProps}
        controlRef={controlRef}
        onFocus={() => handleFocus()}
        onBlur={() => handleBlur()}
        name={name}
        disabled={disabled}
        options={mappedOptions}
        value={formatValue(value)}
        onChange={params => {
          if (params.option?.isCreatable) {
            setIsCreating(params.option);
          } else {
            onChange(params.value);
          }
        }}
        getOptionLabel={getLabel}
        getValueLabel={getLabel}
        filterOptions={null}
        error={error}
        backspaceClearsInputValue={false}
        backspaceRemoves={false}
        creatable={creatable}
        overrides={{
          ...selectProps.overrides,
          Dropdown: VirtualDropdown,
        }}
        onInputChange={event => setDebouncedValue(event.target.value)}
      />
    </>
  );
}

function SelectField({ label, error, disabled, ...props }) {
  return (
    <FormControl label={label} error={<ErrorMessage error={error} />}>
      {disabled ? (
        <DisabledSelectField error={error} {...props} />
      ) : (
        <EnabledSelectField error={error} {...props} />
      )}
    </FormControl>
  );
}

const PropTypes = {
  ...defaultFieldPropTypes,
  onChange: propTypes.func.isRequired,
  data: propTypes.shape({
    source: propTypes.object,
    transformItem: propTypes.func,
    getLabel: propTypes.func,
    creatable: propTypes.bool,
    adapterHookConfig: propTypes.object,
  }),
};
const DefaultProps = {
  ...defaultFieldDefaultValues,
  value: [],
};

EnabledSelectField.propTypes = PropTypes;
EnabledSelectField.defaultProps = DefaultProps;
DisabledSelectField.propTypes = PropTypes;
DisabledSelectField.defaultProps = DefaultProps;

SelectField.propTypes = PropTypes;
SelectField.defaultProps = DefaultProps;

export default SelectField;
