import propTypes from 'prop-types';
import { Modal } from 'baseui/modal';

import useEntity from '@bitsoflove/entity-management/hooks/useEntity';
import DuplicateForm from '~/components/DuplicateEntityAction/DuplicateForm';

function ConfirmDuplicateModal({ item, isOpen, onClose, onComplete }) {
  const { id } = item;
  const entity = useEntity();

  return (
    <Modal isOpen={isOpen} onClose={onClose} unstable_ModalBackdropScroll>
      <DuplicateForm entity={entity} id={id} onSucces={onComplete} />
    </Modal>
  );
}

ConfirmDuplicateModal.propTypes = {
  item: propTypes.object.isRequired,
  isOpen: propTypes.bool,
  onComplete: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired,
};

ConfirmDuplicateModal.defaultProps = {
  isOpen: false,
};

export default ConfirmDuplicateModal;
