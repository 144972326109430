import { Notification, KIND } from 'baseui/notification';
import { Formik } from 'formik';
import _ from 'lodash';
import propTypes from 'prop-types';
import { useRef, useState } from 'react';

import ErrorMessage from '@bitsoflove/entity-management/components/fields/ErrorMessage';
import { Submit } from '@bitsoflove/entity-management/fields';
import useLeaveConfirmation from '@bitsoflove/entity-management/hooks/useLeaveConfirmation';

import useEntity from '../../hooks/useEntity';
import transformErrors from '../../utils/transformErrors';
import FormFields from './FormFields';

function Form({
  onSubmit,
  initialValues,
  isUpdate,
  fieldPayload,
  disabledFields,
}) {
  const entity = useEntity();
  const renderedErrorsRef = useRef({});
  const [asyncErrors, setAsyncErrors] = useState({});
  const { properties: fields, validate } = entity || {};
  const hasSubmitField = fields.find(({ key }) => key === 'submit');

  const clearRenderedErrors = () => {
    renderedErrorsRef.current = {};
  };
  const markRenderedError = key => {
    _.set(renderedErrorsRef.current, key, true);
  };

  return (
    <Formik
      initialValues={JSON.parse(JSON.stringify(initialValues))} // see: https://github.com/formium/formik/issues/1223
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      validate={values => {
        const { __field } = values;

        const result = validate(
          {
            ...values,
          },
          __field,
          initialValues,
        );

        result.done(asyncResult =>
          setAsyncErrors(transformErrors(asyncResult.getErrors())),
        );
        return transformErrors(result.getErrors());
      }}
    >
      {formik => {
        useLeaveConfirmation({
          isEnabled: formik?.dirty,
        });

        const hiddenErrors = Object.keys(formik.errors)
          .filter(key => !renderedErrorsRef.current[key])
          .filter(key => key !== 'submit')
          .map(key => formik.errors[key]);
        return (
          <form
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
            onSubmit={formik.handleSubmit}
          >
            <FormFields
              isUpdate={isUpdate}
              fieldPayload={fieldPayload}
              fields={fields}
              formik={{
                ...formik,
                errors: {
                  ...formik.errors,
                  ...asyncErrors,
                },
              }}
              clearRenderedErrors={clearRenderedErrors}
              markRenderedError={markRenderedError}
              disabledFields={disabledFields}
            />

            {!hasSubmitField && <Submit error={formik.errors.submit} />}
            {!formik.isValid && !formik.isSubmitting && (
              <Notification kind={KIND.negative}>
                The form contains errors, please correct marked fields and
                resubmit.
                {hiddenErrors?.length > 0 && (
                  <>
                    <br />
                    <br />
                    Some errors were not shown in the form:
                    <ErrorMessage error={hiddenErrors} />
                  </>
                )}
              </Notification>
            )}
          </form>
        );
      }}
    </Formik>
  );
}

Form.propTypes = {
  onSubmit: propTypes.func.isRequired,
  initialValues: propTypes.object,
  isUpdate: propTypes.bool,
  fieldPayload: propTypes.object,
  disabledFields: propTypes.object,
};

Form.defaultProps = {
  initialValues: {},
  isUpdate: false,
  fieldPayload: {},
  disabledFields: {},
};

export default Form;
