import { useEffect } from 'react';
import propTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { ButtonGroup } from 'baseui/button-group';
import { colors } from 'baseui/tokens';
import { Notification, KIND as NotificationKind } from 'baseui/notification';

import { Alert } from 'baseui/icon';
import { KIND } from 'baseui/button';
import { useAsyncFn } from 'react-use';
import { StyledSpinnerNext } from 'baseui/spinner';
import useDeleteAdapter from '../../hooks/useDeleteAdapter';
import useEntity from '../../hooks/useEntity';

function ConfirmDeleteModal(props) {
  const { item, isOpen, onClose, onComplete } = props;

  const entity = useEntity();
  const { canBeDeletedIfUsed, adapters } = entity;
  const hasPreDeleteAdapter = adapters?.preDelete;
  const [{ value: usageData, loading, error }, fetchUsage] = useAsyncFn(
    () => entity.adapters?.preDelete({ id: item?.id }),
    [item?.id],
  );

  const [, commitDelete] = useDeleteAdapter(entity, {
    id: item?.id,
  });

  useEffect(() => {
    if (isOpen && hasPreDeleteAdapter) {
      fetchUsage();
    }
  }, [fetchUsage, hasPreDeleteAdapter, isOpen]);

  const canNotBeDeleted = canBeDeletedIfUsed === false && usageData > 0;

  return (
    <Modal unstable_ModalBackdropScroll onClose={onClose} isOpen={isOpen}>
      <ModalHeader>Delete?</ModalHeader>
      {error ? (
        <ModalBody>Something went wrong fetching usage data.</ModalBody>
      ) : (
        <>
          <ModalBody>
            {loading ? (
              <StyledSpinnerNext />
            ) : (
              <>
                Are you sure you want to delete {entity.name} &quot;
                {entity.getHumanReadableName?.(item) || item?.id}&quot;?{' '}
                {hasPreDeleteAdapter && (
                  <>
                    This entity is linked to{' '}
                    <strong>{usageData} other entities</strong>.
                  </>
                )}
                {canNotBeDeleted && (
                  <Notification
                    overrides={{
                      Body: {
                        style: {
                          width: '100%',
                        },
                      },
                    }}
                    kind={NotificationKind.warning}
                  >
                    {() =>
                      'This entity can only be deleted if all linked entities are deleted.'
                    }
                  </Notification>
                )}
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <ModalButton type="button" onClick={onClose}>
                Cancel
              </ModalButton>
              <ModalButton
                type="button"
                disabled={loading || canNotBeDeleted}
                style={{
                  backgroundColor: colors?.red400,
                  color: colors?.white,
                }}
                overrides={{
                  kind: KIND.primary,
                }}
                startEnhancer={() => <Alert size={20} />}
                onClick={async () => {
                  await commitDelete();
                  onComplete();
                }}
              >
                Delete
              </ModalButton>
            </ButtonGroup>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
}

ConfirmDeleteModal.propTypes = {
  item: propTypes.object.isRequired,
  isOpen: propTypes.bool,
  onComplete: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired,
};

ConfirmDeleteModal.defaultProps = {
  isOpen: false,
};

export default ConfirmDeleteModal;
