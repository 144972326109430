import { DatePicker } from 'baseui/datepicker';
import { TimePicker } from 'baseui/timepicker';
import { FormControl } from 'baseui/form-control';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { bool, oneOf } from 'prop-types';

import { defaultFieldDefaultValues, defaultFieldPropTypes } from './Types';
import ErrorMessage from './ErrorMessage';

function DatePickerField({
  name,
  label,
  error,
  value,
  onChange,
  timeSelectStart,
  range,
  timeFormat,
  ...props
}) {
  const showTimeSelect = timeSelectStart && !range;
  const parsedValue =
    typeof value === 'string' && value ? new Date(value) : value;
  return (
    <FormControl label={label} error={<ErrorMessage error={error} />}>
      <FlexGrid
        flexGridColumnCount={showTimeSelect ? [1, 1, 2, 4] : 1}
        flexGridColumnGap="scale100"
        flexGridRowGap="scale100"
      >
        <FlexGridItem>
          <DatePicker
            name={name}
            error={!!error}
            value={parsedValue}
            onChange={({ date }) => {
              onChange(date);
            }}
            timeSelectStart={timeSelectStart}
            overrides={{
              TimeSelect: {
                props: {
                  format: timeFormat,
                },
              },
            }}
            range={range}
            {...props}
          />
        </FlexGridItem>
        {showTimeSelect && (
          <FlexGridItem>
            <TimePicker
              value={parsedValue}
              error={!!error}
              nullable
              onChange={onChange}
              format={timeFormat}
            />
          </FlexGridItem>
        )}
      </FlexGrid>
    </FormControl>
  );
}

DatePickerField.propTypes = {
  ...defaultFieldPropTypes,
  timeSelectStart: bool,
  range: bool,
  timeFormat: oneOf([12, 24]),
};

DatePickerField.defaultProps = {
  ...defaultFieldDefaultValues,
  timeSelectStart: false,
  range: false,
  timeFormat: 24,
};

export default DatePickerField;
