export const ROLES = {
  ADMIN: 'admin',
  CONTENT_TEAM: 'content team',
  CUSTOMER: 'customer',
  READ_ONLY: 'read only',
  ROOT: 'root',
  SITE_ADMIN: 'site admin',
  SUPPORT: 'support',
};

export const FULL_ACCESS_ROLES = [ROLES.ROOT, ROLES.ADMIN];
