import propTypes from 'prop-types';
import { Input } from 'baseui/input';

/**
 * Simple display component which tries to show the name or title property of the entity.
 * Falls back to showing the id.
 */
function EntityPickerSimpleDisplay({ value, placeholder }) {
  const { id, title, name } = value;
  return (
    <Input
      placeholder={placeholder}
      value={name || title || id || ''}
      disabled
    />
  );
}
EntityPickerSimpleDisplay.propTypes = {
  value: propTypes.shape({
    id: propTypes.string.isRequired,
    name: propTypes.string,
    title: propTypes.string,
  }),
  placeholder: propTypes.string,
};
EntityPickerSimpleDisplay.defaultProps = {
  value: null,
  placeholder: '',
};

export default EntityPickerSimpleDisplay;
