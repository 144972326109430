import { bool, string } from 'prop-types';
import { useStyletron } from 'styletron-react';

export const FILE_TYPES = ['file', 'e-plan', 'spare-part'];

function FilePreview({ src, title, type, inline }) {
  const [css] = useStyletron();
  let className = css({});

  if (!inline) {
    className = css({
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    });
  }

  return (
    <div className={className}>
      {!FILE_TYPES.includes(type) && (
        <img src={src} style={{ height: '3em', width: 'auto' }} alt={type} />
      )}
      {FILE_TYPES.includes(type) && (
        <a href={src} target="__blank" download={title}>
          {title || 'No title found for document'}
        </a>
      )}
    </div>
  );
}
FilePreview.propTypes = {
  src: string.isRequired,
  type: string.isRequired,
  title: string,
  inline: bool,
};
FilePreview.defaultProps = {
  title: null,
  inline: true,
};

export default FilePreview;
