import {
  string,
  arrayOf,
  shape,
  func,
  checkPropTypes,
  oneOfType,
  object,
} from 'prop-types';

const entityProps = {
  key: string.isRequired,
  name: string.isRequired,
  overviewLabels: arrayOf(oneOfType([string, object])),
  overviewValues: arrayOf(oneOfType([string, func])),
  properties: arrayOf(
    shape({
      key: string.isRequired,
      name: oneOfType([string, func]).isRequired,
      value: func,
    }),
  ),
  adapters: shape({
    get: func,
    find: func,
    create: func,
    update: func,
    delete: func,
  }),
  validate: func,
  getItemTitle: func,
  getHumanReadableName: func,
  filters: arrayOf(
    shape({
      key: string.isRequired,
      name: string.isRequired,
      placeholder: string,
      Filter: func,
    }),
  ),
  hasAdapterAccess: shape({
    get: func,
    find: func,
    create: func,
    update: func,
    delete: func,
  }),
};

export default function createEntity(entity) {
  checkPropTypes(entityProps, entity, 'props', entity.name);

  return entity;
}
