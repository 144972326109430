import { useEffect } from 'react';
import { useAsyncFn } from 'react-use';

import { warn } from '../utils/console';

const useFindAdapter = (entity, variables, providedValue) => {
  const adapter = entity.adapters?.find;

  if (!adapter) {
    const error = `No 'find' adapter configured for ${entity.name}`;
    warn(error);
    return {
      meta: {
        error,
      },
    };
  }

  const [{ value, loading, error }, fetch] = useAsyncFn(
    async options => adapter({ ...variables, ...options }),
    [...Object.values(variables), adapter],
  );

  useEffect(() => {
    if (!providedValue) {
      fetch();
    }
  }, [fetch]);

  return {
    item: value || providedValue,
    meta: {
      loading,
      error,
    },
    refetch: fetch,
  };
};

export default useFindAdapter;
