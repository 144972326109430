import { useContext } from 'react';
import EntityContext from '../Context/EntityContext';
import { warn } from '../utils/console';

const useEntity = () => {
  const entity = useContext(EntityContext);

  if (!entity) {
    warn(
      'The useEntity hook has been called but no entity was set using the EntityContext',
    );
  }

  return entity;
};

export default useEntity;
