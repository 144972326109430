import { Button } from 'baseui/button';
import { FlexGrid } from 'baseui/flex-grid';
import { Search } from 'baseui/icon';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { Notification, KIND } from 'baseui/notification';
import { PLACEMENT } from 'baseui/snackbar/constants';
import { StatefulTooltip } from 'baseui/tooltip';
import propTypes from 'prop-types';
import { useState } from 'react';

function Dump({ children }) {
  return (
    <pre
      style={{
        whiteSpace: 'pre-wrap',
        marginBottom: 10,
      }}
    >
      {children}
    </pre>
  );
}

function RequestError({ message, error, refetch, loading }) {
  const [showDetails, setShowDetails] = useState(false);
  // console.dir(error);
  // @todo display more specific error message when possible
  return (
    error && (
      <FlexGrid
        alignItems="center"
        flexGridColumnGap="scale100"
        justifyContent="start"
        flexGridColumnCount={2}
      >
        <Notification kind={KIND.negative}>
          {message}
          <StatefulTooltip
            content="Show more error details"
            placement={PLACEMENT.auto}
          >
            <Search onClick={() => setShowDetails(true)} />
          </StatefulTooltip>
        </Notification>
        {refetch && (
          <Button
            onClick={refetch}
            kind="secondary"
            overrides={{
              BaseButton: {
                style: ({ $theme }) => ({
                  marginLeft: $theme.sizing.scale100,
                }),
              },
            }}
            isLoading={loading}
          >
            Retry
          </Button>
        )}

        <Modal
          isOpen={showDetails}
          onClose={() => setShowDetails(false)}
          unstable_ModalBackdropScroll
        >
          <ModalHeader>Error details</ModalHeader>
          <ModalBody>
            {error.response?.errors?.map(e => (
              <Dump key={e.toString()}>{e.message || e.toString()}</Dump>
            ))}
            <Dump>{error.message || error.toString()}</Dump>
          </ModalBody>
        </Modal>
      </FlexGrid>
    )
  );
}
RequestError.propTypes = {
  message: propTypes.node,
  error: propTypes.object,
  refetch: propTypes.func,
  loading: propTypes.bool,
};
RequestError.defaultProps = {
  message: 'Something went wrong while loading',
  error: null,
  refetch: null,
  loading: false,
};

export default RequestError;
