import propTypes from 'prop-types';

const ErrorMessage = ({ error }) =>
  error && (
    <ul className="errors">
      {Array.isArray(error) ? (
        error.map(errorMessage => <li key={errorMessage}>{errorMessage}</li>)
      ) : (
        <li>{error}</li>
      )}
    </ul>
  );

ErrorMessage.propTypes = {
  error: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.string),
    propTypes.string,
  ]),
};

ErrorMessage.defaultProps = {
  error: null,
};

export default ErrorMessage;
