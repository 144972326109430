import propTypes from 'prop-types';
import { styled } from 'styletron-react';

const ColorBlock = styled('div', props => ({
  height: '48px',
  minWidth: '48px',
  marginRight: '10px',
  backgroundColor: props?.color,
}));

ColorBlock.propTypes = {
  color: propTypes.string.isRequired,
};

export default ColorBlock;
