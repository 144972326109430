/* eslint-disable react/display-name */
import { Icon } from '@bitsoflove/pattyn-360/lib/components';
import { Checkbox } from 'baseui/checkbox';
import CheckIndeterminate from 'baseui/icon/check-indeterminate';
import { ProgressBar } from 'baseui/progress-bar';
import {
  StyledTable,
  StyledHead,
  StyledHeadCell,
  StyledRow,
  StyledCell,
  SortableHeadCell,
  SORT_DIRECTION,
} from 'baseui/table';
import { colors } from 'baseui/tokens';
import { StatefulTooltip, PLACEMENT } from 'baseui/tooltip';
import propTypes from 'prop-types';
import { memo, useState } from 'react';
import { areEqual, FixedSizeList } from 'react-window';
import { useStyletron } from 'styletron-react';

import { warn } from '@bitsoflove/entity-management/utils/console';
import isTableColumnVisible from '@bitsoflove/entity-management/utils/isTableColumnVisible';

import OverviewActions from '../../components/OverviewActions';
import useEntity from '../../hooks/useEntity';
import ChooseTableColumnsModal from './ChooseTableColumnsModal';

const SORT_DIRECTIONS = [null, SORT_DIRECTION.ASC, SORT_DIRECTION.DESC];

export const row = memo(props => {
  const { data, index, style } = props;
  const {
    items,
    onUpdate,
    onDelete,
    onSelectionChange,
    selection,
    overviewLabels,
    overviewValues,
    actions,
  } = data;

  const item = items[index];

  return (
    <StyledRow
      style={{
        backgroundColor: index % 2 === 0 ? colors.gray100 : colors.white,
        ...style,
      }}
      key={item.id}
    >
      <StyledCell style={{ maxWidth: '50px' }}>
        <Checkbox
          checked={selection.find(({ id }) => id === item.id)}
          onChange={() => {
            onSelectionChange(item);
          }}
        />
      </StyledCell>
      {overviewValues.map((overviewValue, valueIndex) => {
        let value = '';
        if (typeof overviewValue === 'string') {
          value = item[overviewValue];
        } else if (typeof overviewValue === 'function') {
          value = overviewValue(item);
        }

        const overviewLabel = overviewLabels[valueIndex];
        const { showTooltip, wrap, width, actionable = false } =
          overviewLabel || {};

        const hasCustomActions = !!actions;

        const overviewActions = actionable ? (
          <div>
            {hasCustomActions ? (
              actions?.(item)
            ) : (
              <OverviewActions
                item={item}
                onUpdate={onUpdate}
                onDelete={onDelete}
                actions={actions}
              />
            )}
          </div>
        ) : null;

        const renderedValue = wrap ? (
          <>
            {value}
            {overviewActions}
          </>
        ) : (
          <span
            style={{
              overflow: 'hidden',
              maxWidth: '100%',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {value}
            {overviewActions}
          </span>
        );

        return (
          <StyledCell
            key={`${item.id}-${overviewLabel?.name || overviewLabel}`}
            style={{
              overflow: 'hidden',
              height: '100%',
              alignItems: 'center',
              maxWidth: width,
              minWidth: width,
            }}
          >
            {showTooltip ? (
              <StatefulTooltip
                content={() => value}
                showArrow
                placement={PLACEMENT.right}
              >
                {renderedValue}
              </StatefulTooltip>
            ) : (
              renderedValue
            )}
          </StyledCell>
        );
      })}
    </StyledRow>
  );
}, areEqual);

row.propTypes = {
  data: propTypes.shape({
    items: propTypes.array,
    entity: propTypes.object,
    actions: propTypes.oneOfType([propTypes.func, propTypes.node]),
    onUpdate: propTypes.func,
    onDelete: propTypes.func,
    overrides: propTypes.shape({ ActionsCell: propTypes.object }),
    overviewLabels: propTypes.array.isRequired,
    overviewValues: propTypes.array.isRequired,
  }).isRequired,
  index: propTypes.number.isRequired,
  style: propTypes.object.isRequired,
  onSelectionChange: propTypes.func,
  selection: propTypes.array,
};

row.defaultProps = {
  onSelectionChange: null,
  selection: [],
};

const LIST_ITEM_HEIGHT = 63;

const renderHeadCell = ({
  allowSort,
  overviewLabel,
  handleSortChange,
  orderDirection,
  orderBy,
  setTableColumnsModalOpen,
}) => {
  // const isSortable = overviewLabel?.sortable || false;
  const { sortable = false, width } = overviewLabel || {};

  const isString = typeof overviewLabel === 'string';
  const isActionable = !isString && !!overviewLabel?.actionable;
  const name = isString ? overviewLabel : overviewLabel?.name;
  const key = isString ? overviewLabel : overviewLabel?.key;

  const onActionsHeaderClicked = e => {
    e.stopPropagation();
    setTableColumnsModalOpen(true);
  };

  const actions = isActionable ? (
    <span style={{ paddingLeft: '10px', cursor: 'pointer' }}>
      <Icon onClick={e => onActionsHeaderClicked(e)} icon="tc-settings" />{' '}
    </span>
  ) : null;

  if (sortable && allowSort) {
    if (handleSortChange === null) {
      warn('Rendering a sortable header cell, but no sort handler provided.');
    }

    return (
      <SortableHeadCell
        overrides={{
          HeadCell: {
            props: {
              style: {
                maxWidth: width,
                minWidth: width,
                maxHeight: '50px',
              },
            },
          },
        }}
        direction={key === orderBy && orderDirection}
        onSort={() => handleSortChange({ orderBy: key, orderDirection })}
        key={key}
        title={
          <>
            {key !== orderBy && <CheckIndeterminate />}
            {name}
            {actions}
          </>
        }
      />
    );
  }

  return (
    <StyledHeadCell
      key={key}
      style={{
        alignItems: 'center',
        maxWidth: width,
        minWidth: width,
        maxHeight: '50px',
      }}
    >
      <span>
        {name}
        {actions}
      </span>
    </StyledHeadCell>
  );
};

renderHeadCell.propTypes = {
  allowSort: propTypes.bool.isRequired,
  overviewLabel: propTypes.oneOfType([propTypes.string, propTypes.object])
    .isRequired,
  handleSortChange: propTypes.func,
  orderBy: propTypes.string,
  orderDirection: propTypes.string,
  setTableColumnsModalOpen: propTypes.func.isRequired,
};

renderHeadCell.defaultProps = {
  handleSortChange: null,
  orderBy: null,
  orderDirection: null,
};

const Table = ({
  items = [],
  isLoading,
  onUpdate,
  onDelete,
  actions,
  handleSortChange,
  overrides,
  onSelectionAllChange,
  onSelectionChange,
  selection,
}) => {
  const [isTableColumnsModalOpen, setTableColumnsModalOpen] = useState(false);
  const [order, setOrder] = useState({});
  const [css] = useStyletron();
  const entity = useEntity();

  const getOverview = () => {
    const visibleIndices = {};
    // 1. determine which indices are visible
    entity.overviewLabels.forEach((overviewLabel, index) => {
      const isVisible = isTableColumnVisible(entity.key, overviewLabel.key);
      visibleIndices[index] = isVisible;
    });

    // 2. filter out non-visible columns in both arrays
    return {
      overviewLabels: entity.overviewLabels.filter(
        (_item, index) => visibleIndices[index],
      ),
      overviewValues: entity.overviewValues.filter(
        (_item, index) => visibleIndices[index],
      ),
    };
  };

  const { overviewLabels, overviewValues } = getOverview(entity);
  const allowSort = !!handleSortChange;

  if (overviewLabels.length !== overviewValues.length) {
    warn(
      `Unmatching amounts of "overviewLabels" (${overviewLabels.length}) and "overviewValues" (${overviewValues.length}) provided for entity: ${entity.name}`,
    );
  }
  const height = Math.min(items.length, 20) * LIST_ITEM_HEIGHT;

  /** With some extra buffer to account for horizontal scrollbar on smaller screens etc */
  const tableActionsHeight = '200px';
  const hasItems = Array.isArray(items) && items.length > 0;
  const table = (
    <div
      style={{
        height: '100%',
        maxHeight: `calc(100% - ${tableActionsHeight})`,
        opacity: isLoading ? 0.5 : 1,
        pointerEvents: isLoading ? 'none' : null,
      }}
    >
      <StyledTable
        style={{
          marginTop: '15px',
          display: hasItems ? 'grid' : 'initial',
          alignContent: 'baseline',
        }}
      >
        <StyledHead
          style={{
            position: 'sticky',
            top: '0px',
            zIndex: 1,
          }}
        >
          <StyledHeadCell style={{ maxWidth: '50px' }}>
            <Checkbox
              isIndeterminate={selection.length > 0}
              onChange={e => {
                if (e.currentTarget.checked) {
                  onSelectionAllChange(items);
                } else {
                  onSelectionAllChange([]);
                }
              }}
            />
          </StyledHeadCell>
          {overviewLabels.map(overviewLabel =>
            renderHeadCell({
              allowSort,
              overviewLabel,
              ...order,
              setTableColumnsModalOpen,
              handleSortChange:
                handleSortChange &&
                (({ orderBy, orderDirection }) => {
                  // default sort direction;
                  let nextDirection = SORT_DIRECTIONS[1];

                  if (order?.orderBy === orderBy) {
                    // sorting by the same column, rotate the direction
                    const currentDirectionIndex = SORT_DIRECTIONS.findIndex(
                      direction => direction === orderDirection,
                    );
                    nextDirection =
                      SORT_DIRECTIONS[currentDirectionIndex + 1] || null;
                  }

                  const nextOrderBy = nextDirection === null ? null : orderBy;

                  setOrder({
                    orderBy: nextOrderBy,
                    orderDirection: nextDirection,
                  });
                  handleSortChange({
                    orderBy: nextOrderBy,
                    orderDirection: nextDirection,
                  });
                }),
            }),
          )}
        </StyledHead>
        {isLoading && <ProgressBar infinite />}
        <div className={css({ height: `${height}px` })}>
          <FixedSizeList
            width="100%"
            height={height}
            itemCount={items.length}
            itemData={{
              items,
              entity,
              actions,
              onUpdate,
              onDelete,
              overrides,
              overviewLabels,
              overviewValues,
              onSelectionChange,
              selection,
            }}
            itemKey={(index, data) => data.items[index].id}
            itemSize={LIST_ITEM_HEIGHT}
          >
            {row}
          </FixedSizeList>
        </div>
      </StyledTable>
    </div>
  );

  return (
    <>
      <ChooseTableColumnsModal
        isOpen={isTableColumnsModalOpen}
        onClose={() => setTableColumnsModalOpen(false)}
      />
      {table}
    </>
  );
};

Table.propTypes = {
  items: propTypes.arrayOf(propTypes.object),
  isLoading: propTypes.bool,
  onUpdate: propTypes.func,
  onDelete: propTypes.func,
  actions: propTypes.oneOfType([propTypes.node, propTypes.func]),
  handleSortChange: propTypes.func,
  overrides: propTypes.shape({
    ActionsCell: propTypes.shape({ props: propTypes.object }),
    ActionsHeaderCell: propTypes.shape({ props: propTypes.object }),
  }),
  onSelectionChange: propTypes.func,
  onSelectionAllChange: propTypes.func,
  selection: propTypes.array,
};

Table.defaultProps = {
  items: [],
  isLoading: false,
  onUpdate: null,
  onDelete: null,
  actions: null,
  handleSortChange: null,
  overrides: {},
  onSelectionChange: null,
  onSelectionAllChange: null,
  selection: [],
};

export default Table;
