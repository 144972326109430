import { Select, SIZE } from 'baseui/select';
import propTypes from 'prop-types';

import { VirtualDropdown } from '../fields/Select/FixedSize';

function SelectFilter({
  name,
  label,
  onChange,
  value,
  data,
  isCompact,
  ...props
}) {
  return (
    <Select
      {...data}
      {...props}
      name={name}
      overrides={{ Dropdown: VirtualDropdown }}
      valueKey="label"
      value={
        typeof value === 'string'
          ? [data.options?.find(({ id }) => id === value) || { id: value }]
          : value
      }
      onChange={params => {
        onChange(params.value?.[0]?.id);
      }}
      filterOutSelected
      size={isCompact ? SIZE.compact : SIZE.default}
    />
  );
}

SelectFilter.propTypes = {
  name: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  value: propTypes.oneOfType([propTypes.array, propTypes.string]),
  data: propTypes.shape({
    options: propTypes.arrayOf(
      propTypes.shape({
        id: propTypes.string,
        label: propTypes.string,
      }),
    ).isRequired,
  }).isRequired,
  onChange: propTypes.func.isRequired,
  isCompact: propTypes.bool,
};

SelectFilter.defaultProps = {
  value: [],
  isCompact: false,
};

export default SelectFilter;
