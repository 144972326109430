import { Pagination } from 'baseui/pagination';
import propTypes from 'prop-types';
import { useState } from 'react';

const PaginationBar = ({
  onNext,
  onPrevious,
  onPageChange,
  numPages,
  currentPage,
}) => {
  const [page, setPage] = useState(1);
  const hasPageCount = numPages !== null;
  const hasPageChangeHandler = !!onPageChange;

  return (
    <Pagination
      overrides={{
        Root: {
          style: () => ({ marginTop: 10 }),
        },
        PrevButton: {
          style: {
            marginRight: 'auto',
          },
          props: {
            disabled: !onPrevious,
          },
        },
        NextButton: {
          props: {
            disabled: !onNext,
          },
        },
        MaxLabel: {
          style: {
            display: hasPageCount ? null : 'none',
          },
        },
        DropdownContainer: {
          style: {
            display: hasPageCount && hasPageChangeHandler ? null : 'none',
          },
        },
      }}
      currentPage={currentPage ?? page}
      numPages={numPages ?? (currentPage ?? page) + 1}
      onPrevClick={() => {
        if (currentPage === null) {
          setPage(value => value - 1);
        }
        if (onPrevious) {
          onPrevious();
        }
      }}
      onNextClick={() => {
        if (onNext) {
          if (currentPage === null) {
            setPage(value => value + 1);
          }

          onNext();
        }
      }}
      onPageChange={({ nextPage }) => {
        if (onPageChange) {
          if (currentPage === null) {
            setPage(nextPage);
          }

          onPageChange(nextPage);
        }
      }}
    />
  );
};

PaginationBar.propTypes = {
  onPageChange: propTypes.func,
  onPrevious: propTypes.func,
  onNext: propTypes.func,
  numPages: propTypes.number,
  currentPage: propTypes.number,
};

PaginationBar.defaultProps = {
  onPageChange: null,
  onPrevious: null,
  onNext: null,
  numPages: null,
  currentPage: null,
};

export default PaginationBar;
