import PaginationBar from '../containers/internal/PaginationBar';

const usePagination = ({ meta, handleChange }) => {
  const disabled = !(meta?.nextProps || meta?.previousProps);

  return [
    <PaginationBar
      numPages={meta?.lastPage}
      currentPage={meta?.currentPage}
      onPageChange={
        meta?.pageProps
          ? nextPage => {
              disabled ? () => {} : handleChange(meta.pageProps(nextPage));
            }
          : null
      }
      onPrevious={
        meta?.previousProps
          ? () => {
              disabled ? () => {} : handleChange(meta.previousProps());
            }
          : null
      }
      onNext={
        meta?.nextProps
          ? () => {
              disabled ? () => {} : handleChange(meta.nextProps());
            }
          : null
      }
    />,
  ];
};

export default usePagination;
