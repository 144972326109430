import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useThrottle } from 'react-use';

import GlobalLoader from '@bitsoflove/entity-management/components/GlobalLoader';

function RouterLoader() {
  const router = useRouter();
  const [isChangingRoute, setIsChangingRoute] = useState(false);
  const throttledIsChanging = useThrottle(isChangingRoute, 300);

  useEffect(() => {
    const handleChangeStart = () => setIsChangingRoute(true);
    const handleChangeEnd = () => setIsChangingRoute(false);

    router.events.on('routeChangeStart', handleChangeStart);
    router.events.on('routeChangeComplete', handleChangeEnd);
    router.events.on('routeChangeError', handleChangeEnd);

    return () => {
      router.events.off('routeChangeStart', handleChangeStart);
      router.events.off('routeChangeComplete', handleChangeEnd);
      router.events.off('routeChangeError', handleChangeEnd);
    };
  });

  return throttledIsChanging ? <GlobalLoader /> : null;
}

RouterLoader.propTypes = {};

export default RouterLoader;
