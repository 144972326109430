import propTypes from 'prop-types';

const UserInfo = ({ user = null }) => {
  return user ? (
    <span>
      {user.firstName} {user.lastName}
    </span>
  ) : null;
};
UserInfo.propTypes = {
  user: propTypes.object,
};

UserInfo.defaultProps = {
  user: { firstName: '-', lastName: '' },
};

export default UserInfo;
