import { global } from '@bitsoflove/pattyn-360/lib/styles';
import { css } from '@emotion/core';

import fonts from './fonts';
import icons from './icons';

const indentStyling = () => {
  const styles = {};
  for (let i = 1; i <= 10; i += 1) {
    styles[`.indent-${i}`] = {
      marginLeft: `${i * 2}rem`,
    };
  }
  return styles;
};

const GlobalStyles = props => css`
  ${global(props)}
  ${fonts}
  ${icons}

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }

  fieldset {
    border: solid 1px black;
    padding: 1rem;
  }

  .ck {
    display: block;
    z-index: 0;

    .ck-editor__main {
      padding: 0;
    }

    .ck-content {
      ${indentStyling()};

      i {
        font-style: italic;
      }
    }
  }

  .rst__tree {
    .rst__rowToolbar {
      align-items: center;

      .rst__toolbarButton {
        margin-right: 3px;
      }
    }

    .rst__row {
      border-radius: 5px;
      overflow: hidden;
      border: solid 1px #424A57;
    }

    .rst__row > .rst__rowContents {
      padding-left: 30px;
    }

    .rst__row > .rst__moveHandle + .rst__rowContents {
      padding-left: 0px;
    }

    .rst__moveHandle {
      width: 30px;
      background: #fff no-repeat center url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' data-baseweb='icon' title='Grab' viewBox='0 0 24 24' class='cl cm cn co cp'%3E%3Ctitle%3EGrab%3C/title%3E%3Cpath fill-rule='evenodd' fill='%23424A57' clip-rule='evenodd' d='M5 8C4.44775 8 4 8.44775 4 9C4 9.55225 4.44775 10 5 10H19C19.5522 10 20 9.55225 20 9C20 8.44775 19.5522 8 19 8H5ZM5 14C4.44775 14 4 14.4478 4 15C4 15.5522 4.44775 16 5 16H19C19.5522 16 20 15.5522 20 15C20 14.4478 19.5522 14 19 14H5Z'%3E%3C/path%3E%3C/svg%3E");
      background-size: 22px;
      border: none;
    }

    .rst__rowContents {
      border: none;
    }

    .rst__rowTitle {
      font-family: 'DIN';
      font-size: 16px;
      font-weight: normal;
    }
  }
`;

export default GlobalStyles;
