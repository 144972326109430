import { useStyletron } from 'baseui';
import { Input } from 'baseui/input';
import propTypes from 'prop-types';

import getFilenameFromPath from '@bitsoflove/entity-management/utils/getFilenameFromPath';
import {
  ASSET_TYPE_COVER,
  ASSET_TYPE_IMAGE,
  ASSET_TYPE_THUMBNAIL,
} from '~/constants';

function EntityPickerImagePreview({ value, placeholder }) {
  const [css, theme] = useStyletron();

  const imageStyles = css({
    maxWidth: '100px',
    width: 'auto',
    height: 'auto',
    border: `${theme.sizing.scale100} solid ${theme.colors.backgroundTertiary}`,
    marginRight: theme.sizing.scale0,
  });

  const { type, src, title, name } = value || {};
  const isImage =
    ASSET_TYPE_IMAGE === type ||
    ASSET_TYPE_THUMBNAIL === type ||
    ASSET_TYPE_COVER === type;

  return (
    <>
      {src && isImage && (
        <img className={imageStyles} src={src} alt={name || title} />
      )}
      <Input
        placeholder={placeholder}
        value={name || title || getFilenameFromPath(src)}
        disabled
      />
    </>
  );
}

EntityPickerImagePreview.propTypes = {
  value: propTypes.shape({
    id: propTypes.string.isRequired,
    name: propTypes.string,
    title: propTypes.string,
    src: propTypes.string.isRequired,
  }),
  placeholder: propTypes.string,
};

EntityPickerImagePreview.defaultProps = {
  value: null,
  placeholder: '',
};

export default EntityPickerImagePreview;
