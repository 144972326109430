import { test } from 'vest';

const testNested = (key, data, validate) => {
  if (data) {
    Object.keys(data).forEach(pivotKey => {
      const nestedObject = data?.[pivotKey];

      validate(function scopedTest(nestedKey, message, validateFunction) {
        test(`${key}.${pivotKey}.${nestedKey}`, message, validateFunction);
      }, nestedObject);
    });
  }
};

export default testNested;
