export const ASSET_TYPE_IMAGE = 'image';
export const ASSET_TYPE_THUMBNAIL = 'thumbnail';
export const ASSET_TYPE_FILE = 'file';
export const ASSET_TYPE_E_PLAN = 'e-plan';
export const ASSET_TYPE_SPARE_PART = 'spare-part';
export const ASSET_TYPE_LINE_SCHEMA = 'line-schema';
export const ASSET_TYPE_MANUAL = 'vendor-manual';
export const ASSET_TYPE_DOCUMENTATION = 'vendor-documentation';
export const ASSET_TYPE_COVER = 'cover';

export const ACCEPTED_FILE_TYPES = {
  [ASSET_TYPE_THUMBNAIL]: ['image/png', 'image/jpg', 'image/webp'],
  [ASSET_TYPE_COVER]: ['image/png', 'image/jpg', 'image/webp'],
};

export const TRANSLATION_REQUEST_STATUSES = [
  { id: 'CREATED', label: 'Created' },
  { id: 'REQUESTED', label: 'Requested' },
  { id: 'REQUEST_APPROVED', label: 'Request Approved' },
  { id: 'IMPORTED', label: 'Imported' },
  { id: 'VALIDATED', label: 'Validated' },
];
