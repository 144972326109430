import propTypes from 'prop-types';

export const defaultFieldPropTypes = {
  name: propTypes.oneOfType([propTypes.string, propTypes.func]),
  error: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.string),
    propTypes.string,
  ]),
  type: propTypes.string,
};

export const defaultFieldDefaultValues = {
  name: null,
  type: null,
  error: null,
};
