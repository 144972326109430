import propTypes from 'prop-types';
import { createContext, useContext, useState } from 'react';

import { EntitySelect } from '@bitsoflove/entity-management/filters';

import TagComponent from '../../../components/Tag';
import Tag from '../Tag';

export const TagFilterContext = createContext([]);
TagFilterContext.displayName = 'TagFilterContext';

export const TagFilterProvider = ({ children }) => {
  const filterState = useState([]);

  return (
    <TagFilterContext.Provider value={filterState}>
      {children}
    </TagFilterContext.Provider>
  );
};

TagFilterProvider.propTypes = {
  children: propTypes.node.isRequired,
};

const TagFilterSelect = ({ value, onChange, ...props }) => {
  const [, setValue] = useContext(TagFilterContext);

  return (
    <EntitySelect
      {...props}
      value={value || []}
      onChange={newValue => {
        setValue(newValue);
        onChange(newValue);
      }}
    />
  );
};

TagFilterSelect.propTypes = {
  value: propTypes.oneOfType([propTypes.array, propTypes.string]),
  onChange: propTypes.func.isRequired,
};

TagFilterSelect.defaultProps = {
  value: null,
};

const FilterTag = ({ option }) => (
  <TagComponent
    label={option.label}
    color={option.meta?.color || '#333'}
    overrides={{
      Root: {
        style: {
          margin: 0,
        },
      },
      Text: {
        style: {
          maxWidth: 'unset',
        },
      },
    }}
  />
);

FilterTag.propTypes = {
  option: propTypes.shape({
    label: propTypes.string.isRequired,
    meta: propTypes.shape({
      color: propTypes.string,
    }),
  }).isRequired,
};

export default {
  key: 'tag',
  name: 'tags',
  Filter: TagFilterSelect,
  data: {
    multi: true,
    source: Tag,
    getLabel: FilterTag,
    overrides: {
      Tag: {
        props: {
          overrides: {
            Root: {
              style: {
                marginTop: '2px',
                marginBottom: '2px',
                marginLeft: 0,
                marginRight: 0,
                borderTopWidth: 0,
                backgroundColor: 'transparent',
              },
            },
            Action: {
              style: {
                color: '#333',
                marginLeft: 0,
              },
            },
            Text: {
              style: {
                maxWidth: 'unset',
              },
            },
          },
        },
      },
    },
    transformItem: item => ({
      id: item.id,
      label: item.name,
      meta: item.meta,
    }),
  },
};
