import { useEffect, useState } from 'react';

const useFilters = ({ handleChange }) => {
  const [queuedFilterChange, setQueuedFilterChange] = useState(null);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    if (queuedFilterChange) {
      handleChange({
        page: queuedFilterChange.page,
        count: queuedFilterChange.count,
        filters: queuedFilterChange,
      });
      setQueuedFilterChange(null);
    }
  }, [queuedFilterChange]);

  const handleFilterChange = values => {
    setQueuedFilterChange(values);
    setFilters(values);
  };

  return [filters, handleFilterChange];
};

export default useFilters;
