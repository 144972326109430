export default [
  {
    key: 'created_at',
    name: 'Created at',
    sortable: true,
    wrap: true,
    width: 120,
  },
  {
    key: 'created_by',
    name: 'Created by',
    sortable: true,
    wrap: true,
    width: 140,
  },
  {
    key: 'updated_at',
    name: 'Updated at',
    sortable: true,
    wrap: true,
    width: 130,
  },
  {
    key: 'updated_by',
    name: 'Updated by',
    sortable: true,
    wrap: true,
    width: 140,
  },
];
