import { useAsyncFn } from 'react-use';
import { warn } from '../utils/console';

const useDeleteAdapter = (entity, { id }) => {
  const adapter = entity.adapters?.delete;

  if (!adapter) {
    warn(`No 'delete' adapter configured for ${entity.name}`);
    return {};
  }

  return useAsyncFn(async () => adapter({ id }), [id, entity]);
};

export default useDeleteAdapter;
