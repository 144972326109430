import GlobalLoader from '@bitsoflove/entity-management/components/GlobalLoader';

export const AUTH_ROUTES = {
  authenticated: '/dashboard',
  login: '/',
  requestPassword: '/account/request-password',
};

const AUTH_CONFIG = {
  routes: AUTH_ROUTES,
  GlobalLoader,
};

export default AUTH_CONFIG;
