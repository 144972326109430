import { ModalBody, ModalHeader } from 'baseui/modal';
import { StyledSpinnerNext } from 'baseui/spinner';
import propTypes from 'prop-types';

// @todo: an extra external container should be made instead of using this internal container
import EntityContext from '@bitsoflove/entity-management/Context/EntityContext';
import RequestError from '@bitsoflove/entity-management/components/RequestError';
import Form from '@bitsoflove/entity-management/containers/internal/Form';
import useFindAdapter from '@bitsoflove/entity-management/hooks/useFindAdapter';
import handleSubmitError from '@bitsoflove/entity-management/utils/handleSubmitError';

const DuplicateForm = ({ entity, id, onSucces }) => {
  const { item, meta, refetch } = useFindAdapter(entity, { id });
  const { loading, error } = meta;

  return (
    <>
      <ModalHeader>
        Duplicate {item && <>&quot;{entity.getHumanReadableName(item)}&quot;</>}
      </ModalHeader>
      <ModalBody>
        {(loading || !item) && <StyledSpinnerNext />}
        {error && (
          <RequestError
            message="Something went wrong."
            error={error}
            refetch={refetch}
          />
        )}
        {!loading && item && (
          <EntityContext.Provider value={entity}>
            <Form
              fieldPayload={{
                isDuplicate: true,
              }}
              onSubmit={async (values, { setSubmitting, setFieldError }) => {
                try {
                  setFieldError('submit', null);
                  setSubmitting(true);

                  const duplicateItem = await entity?.adapters?.duplicate({
                    values: {
                      id,
                      ...values,
                    },
                  });

                  onSucces(duplicateItem);
                } catch (e) {
                  handleSubmitError({
                    fallback: 'Unable to duplicate entity',
                    setSubmitting,
                    setFieldError,
                    error: e,
                  });
                }
              }}
              initialValues={
                item &&
                entity?.properties
                  .filter(({ isReadOnly }) => !isReadOnly)
                  .reduce(
                    (all, field) => ({
                      ...all,
                      [field.key]: field.value(item),
                    }),
                    {},
                  )
              }
            />
          </EntityContext.Provider>
        )}
      </ModalBody>
    </>
  );
};

DuplicateForm.propTypes = {
  entity: propTypes.object.isRequired,
  id: propTypes.string.isRequired,
  onSucces: propTypes.func.isRequired,
};

export default DuplicateForm;
