import { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Input, SIZE } from 'baseui/input';
import { useDebounce } from 'react-use';

function TextFilter({ name, label, onChange, value, isCompact, ...props }) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const [, cancelDebounce] = useDebounce(
    () => {
      if (debouncedValue !== value) {
        onChange(debouncedValue);
      }
    },
    350,
    [debouncedValue],
  );

  useEffect(() => cancelDebounce, [cancelDebounce]);

  return (
    <Input
      {...props}
      size={isCompact ? SIZE.compact : SIZE.default}
      name={name}
      onChange={e => setDebouncedValue(e.target.value)}
      value={debouncedValue}
      clearable
    />
  );
}

TextFilter.propTypes = {
  name: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  value: propTypes.oneOfType([propTypes.array, propTypes.string]),
  onChange: propTypes.func.isRequired,
  isCompact: propTypes.bool,
};

TextFilter.defaultProps = {
  value: '',
  isCompact: false,
};

export default TextFilter;
