import { FileUploader } from 'baseui/file-uploader';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { FormControl } from 'baseui/form-control';
import { number } from 'prop-types';
import { useMemo, useState } from 'react';

import { ACCEPTED_FILE_TYPES } from '~/constants';
import formatBytes from '~/utils/formatBytes';

import ErrorMessage from './ErrorMessage';
import { FILE_TYPES } from './FilePreview';
import { defaultFieldDefaultValues, defaultFieldPropTypes } from './Types';

const DEFAULT_MAX_UPLOAD_SIZE = 104857600;

function invalidDropMessage(file, { maxSize }) {
  if (file.size > maxSize) {
    return `Please select a file smaller than ${formatBytes(maxSize)}`;
  }
  return 'Please select a valid file';
}

function FileUploadField({
  name,
  label,
  data,
  error,
  value,
  onChange,
  parent,
  maxSize,
  ...props
}) {
  const type = data?.type || parent.type?.[0]?.id || parent.type || 'image';
  const [droppedInvalidFile, setDroppedInvalidFile] = useState();

  const renderErrors = () => {
    const errors = [];
    if (error) {
      errors.push(error);
    }
    if (droppedInvalidFile) {
      errors.push(
        invalidDropMessage(droppedInvalidFile, {
          maxSize,
          ...props,
        }),
      );
    }
    if (errors.length > 0) {
      return <ErrorMessage error={errors} />;
    }

    return null;
  };

  const preview = useMemo(() => {
    if (value) {
      try {
        if (value instanceof File) {
          return {
            type,
            title: value.name,
            src: URL.createObjectURL(value),
          };
        }

        return value;
      } catch (e) {
        return null;
      }
    }

    return null;
  }, [value]);

  return (
    <FormControl
      label={label}
      error={renderErrors()}
      caption={() => !!maxSize && `Max upload size ${formatBytes(maxSize)}`}
    >
      <FlexGrid
        flexGridColumnCount={value ? 2 : 1}
        flexGridColumnGap="scale800"
        flexGridRowGap="scale800"
      >
        {value && (
          <FlexGridItem
            overrides={{
              Block: {
                style: {
                  width: 'auto',
                  flexGrow: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '45%',
                },
              },
            }}
          >
            {!FILE_TYPES.includes(type) && (
              <img
                src={preview.src}
                alt={preview.title || preview.filename}
                style={{ maxWidth: '100%', maxHeight: 250 }}
              />
            )}
            <a
              href={preview.src}
              target="__blank"
              download={preview.title || preview.filename}
              style={{ marginTop: 10 }}
            >
              {preview.title ||
                preview.filename ||
                'No title found for document'}
            </a>
          </FlexGridItem>
        )}
        <FlexGridItem>
          <FileUploader
            className="fullwidth"
            name={name}
            error={error}
            value={value || ''}
            onFileDialogCancel={() => setDroppedInvalidFile(null)}
            onDropRejected={files => {
              setDroppedInvalidFile(files?.[0]);
            }}
            onDropAccepted={files => {
              onChange(files?.[0]);
              setDroppedInvalidFile(null);
            }}
            multiple={false}
            maxSize={maxSize}
            accept={
              Object.keys(ACCEPTED_FILE_TYPES).includes(type)
                ? ACCEPTED_FILE_TYPES[type]
                : undefined
            }
            {...props}
          />
        </FlexGridItem>
      </FlexGrid>
    </FormControl>
  );
}

FileUploadField.propTypes = {
  ...defaultFieldPropTypes,
  // max upload size in bytes
  maxSize: number,
};

FileUploadField.defaultProps = {
  ...defaultFieldDefaultValues,
  maxSize: DEFAULT_MAX_UPLOAD_SIZE,
};

export default FileUploadField;
