import { GraphQLClient } from 'graphql-request';

import { setClientToken } from '~/api/client';
import { API_URL } from '~/config';
import { REFRESH_TOKEN_MUTATION } from '~/queries/authentication';

// a separate client to handle login and refresh tokens
export const authClient = new GraphQLClient(API_URL);

const LOCALSTORAGE_KEY = 'pattyn-cms-auth';
let authInfo = null;

export function setToken({ accessToken, refreshToken, expiresIn, user }) {
  authInfo = {
    accessToken,
    refreshToken,
    userClients: Array.from(
      new Set(user.sites?.map(site => site.client?.id).filter(s => s)),
    ),
    userRoles: user.roles.map(({ name }) => name),
    userSites: user.sites?.map(({ id }) => id) ?? [],
    expiration: new Date().getTime() + expiresIn,
  };

  localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(authInfo));
  setClientToken(accessToken);
}

export function clearToken() {
  // @todo should this also invalidate the tokens?
  localStorage.removeItem(LOCALSTORAGE_KEY);
  setClientToken(null);
}

function getRefreshToken() {
  authInfo = authInfo || JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY));
  return authInfo?.refreshToken || null;
}

/**
 * Try refreshing the access token.
 *
 * - If no refresh token is present will immediately return null (and do nothing else).
 * - If refresh token call errors will clear the login state.
 * - Returns token on success.
 */
async function refreshAccessToken() {
  const refreshToken = getRefreshToken();

  if (refreshToken) {
    try {
      const refreshResult = await authClient.request(REFRESH_TOKEN_MUTATION, {
        input: {
          token: refreshToken,
        },
      });

      if (refreshResult?.data?.refreshToken) {
        setToken(refreshResult?.data?.refreshToken);
        return refreshResult.data.refreshToken.accessToken;
      }

      console.warn('Could not refresh token', refreshResult);
      clearToken();
    } catch (e) {
      console.error('Refreshing access token failed, clearing login state.', e);
      clearToken();
    }
  }

  console.warn('Did not refresh token because none was found.');
  return null;
}

/**
 * Get current locally stored access token, check expiration and refresh token if expired.
 */
export async function getAccessToken() {
  // check if token is in local storage
  authInfo = authInfo || JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY));

  if (!authInfo) {
    return null;
  }

  const { expiration = 0 } = authInfo;

  if (new Date() > expiration) {
    return refreshAccessToken();
  }

  return authInfo?.accessToken || null;
}

export function getUserRoles() {
  return authInfo?.userRoles || [];
}

export function getUserSites() {
  return authInfo?.userSites || [];
}

export function getUserClients() {
  return authInfo?.userClients || [];
}
