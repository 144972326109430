import propTypes from 'prop-types';
import { Plus } from 'baseui/icon';
import { Button, KIND } from 'baseui/button';
import { styled } from 'styletron-react';

import FilterBar from '../containers/internal/FilterBar';
import useEntity from '../hooks/useEntity';
import hasAdapterAccess from '../utils/hasAdapterAccess';

const StyledActionBar = styled('div', ({ isLoading }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginTop: '25px',
  marginBottom: '15px',
  pointerEvents: isLoading ? 'none' : null,
  opacity: isLoading ? 0.5 : 1,
}));

const OverviewActionBar = ({ onFilterChange, onCreateRouted, isLoading }) => {
  const entity = useEntity();

  return (
    <StyledActionBar isLoading={isLoading}>
      <FilterBar onChange={onFilterChange} />
      {entity?.adapters?.create && hasAdapterAccess(entity, 'create') && (
        <Button
          startEnhancer={() => <Plus size="scale700" />}
          kind={KIND.primary}
          onClick={onCreateRouted}
        >
          Create
        </Button>
      )}
    </StyledActionBar>
  );
};

OverviewActionBar.defaultProps = {
  isLoading: false,
};

OverviewActionBar.propTypes = {
  onFilterChange: propTypes.func.isRequired,
  onCreateRouted: propTypes.func.isRequired,
  isLoading: propTypes.bool,
};

export default OverviewActionBar;
