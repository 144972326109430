import { useEffect } from 'react';
import { MaskedInput } from 'baseui/input';
import { FormControl } from 'baseui/form-control';
import InputMask from 'react-input-mask';
import hsl from 'hsl-to-hex';
import { Button } from 'baseui/button';

import { defaultFieldDefaultValues, defaultFieldPropTypes } from './Types';
import ErrorMessage from './ErrorMessage';
import ColorBlock from '../ColorBlock';

const randomColor = () => {
  return hsl(Math.floor(Math.random() * 256), 75, 50);
};

function ColorField({ name, label, error, value, onChange, ...props }) {
  useEffect(() => {
    if (!value) {
      onChange(randomColor());
    }
  });

  return (
    <FormControl label={label} error={<ErrorMessage error={error} />}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ColorBlock color={value} />
        <MaskedInput
          name={name}
          error={error}
          value={value || ''}
          mask="#******"
          overrides={{
            Input: <InputMask mask={['#', /[0-9a-z]{0, 6}/i]} />,
          }}
          onChange={e => {
            if (/^#[0-9a-f]{0,6}$/i.test(e.target.value.trim())) {
              onChange(e.target.value.trim());
            } else {
              onChange(value || '');
            }
          }}
          {...props}
        />
        <Button
          type="button"
          style={{ marginLeft: 10 }}
          onClick={() => {
            onChange(randomColor());
          }}
        >
          Random
        </Button>
      </div>
    </FormControl>
  );
}

ColorField.propTypes = {
  ...defaultFieldPropTypes,
};

ColorField.defaultProps = {
  ...defaultFieldDefaultValues,
};

export default ColorField;
