import mapArrayToAssocObject from '~/utils/mapArrayToAssocObject';

const shouldPatchValue = (value, itemMap) => {
  return value && !itemMap[value.id];
};

/**
 * Description:
 * Given we are using a patch-value approach, and given that the value might not have exactly the same props as the items,
 * we need to ensure that the item we're patching also has a $.name property in order to make the select display the option label correctly.
 * This is why the $.name prop is explicitly set here.
 * It solves the problem with tags where the value is not available in the first page of the selected items.
 * eg. on entities/machine/920bd8e6-35c0-4881-9ff3-87f017a9017c
 *
 * @param {*} valueItem
 * @returns
 */
const getPatchItem = valueItem => {
  return {
    name: valueItem.name || valueItem.label,
    ...valueItem,
  };
};

const patchValuesIntoItems = (unpatchedItems = [], value, isMultiSelect) => {
  const itemMap = mapArrayToAssocObject(unpatchedItems, 'id');
  const items = [...unpatchedItems];

  if (isMultiSelect && Array.isArray(value)) {
    value.forEach(valueItem => {
      if (shouldPatchValue(valueItem, itemMap)) {
        const patchItem = getPatchItem(valueItem);
        items.push(patchItem);
      }
    });
  } else if (!isMultiSelect && shouldPatchValue(value, itemMap)) {
    const patchItem = getPatchItem(value);
    items.push(patchItem);
  }

  return items;
};

export default patchValuesIntoItems;
