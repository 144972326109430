import propTypes from 'prop-types';
import { Button } from 'baseui/button';
import { Check } from 'baseui/icon';
import RequestError from '@bitsoflove/entity-management/components/RequestError';

import { useFormikContext } from 'formik';
import { defaultFieldDefaultValues } from './Types';

function SubmitField({
  type = 'submit',
  label = 'Save',
  error,
  disabled,
  // prevent stuff from ending up in DOM
  hasFieldAccess,
  Field,
  ...props
}) {
  const formik = useFormikContext();
  const saveDisabled = !formik.isValid || formik.isSubmitting;
  return (
    <>
      <Button
        type={type}
        startEnhancer={() => <Check size={24} />}
        disabled={saveDisabled || disabled}
        isLoading={formik.isSubmitting}
        {...props}
      >
        {label}
      </Button>

      {error && <RequestError {...error} />}
    </>
  );
}
SubmitField.propTypes = {
  label: propTypes.string,
  disabled: propTypes.bool,
  hasFieldAccess: propTypes.func,
  Field: propTypes.node,
  error: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.string),
    propTypes.string,
  ]),
  type: propTypes.string,
};
SubmitField.defaultProps = {
  ...defaultFieldDefaultValues,
};

export default SubmitField;
