import _ from 'lodash';

// The vest validation library can't handle nested values at the moment of implementation
// The keys are returned in the following format `translations.en.description`
// A simple loop using the lodash set function wil create the nested structure we need
function transformErrors(errors) {
  if (typeof errors !== 'object') {
    return errors;
  }

  const result = Object.keys(errors).reduce((transformed, key) => {
    const newTransformed = { ...transformed };

    _.set(newTransformed, key, errors[key]);

    return newTransformed;
  }, {});

  return result;
}

export default transformErrors;
