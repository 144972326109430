import { Select, SIZE } from 'baseui/select';
import propTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'react-use';

import useGetAdapter from '@bitsoflove/entity-management/hooks/useGetAdapter';

import { VirtualDropdown } from '../fields/Select/FixedSize';

function EntitySelectFilter({
  name,
  label,
  onChange,
  value,
  data,
  isCompact,
  ...props
}) {
  const { source, transformItem, getLabel = null, ...selectProps } = data;
  const { items = [], meta, refetch } = useGetAdapter(source);
  const { loading } = meta;
  const [debouncedValue, setDebouncedValue] = useState(null);
  const [hasFocus, setHasFocus] = useState(null);
  const [isFetchingItems, setIsFetchingItems] = useState(null);
  const controlRef = useRef(null);

  const debounceTimeout = 500;
  const [, cancelDebounce] = useDebounce(
    () => {
      if (debouncedValue !== null) {
        setIsFetchingItems(true);
        refetch({ filters: { query: debouncedValue } });
      }
    },
    debounceTimeout,
    [debouncedValue],
  );

  const mappedOptions = loading ? [] : items?.map(transformItem || (i => i));
  const itemsEffectKey = mappedOptions.map(option => option.id).join('|');

  useEffect(() => cancelDebounce, [cancelDebounce]);

  useEffect(() => {
    const shouldReset =
      hasFocus && controlRef.current && isFetchingItems !== null;

    if (shouldReset) {
      controlRef.current.focus();
      setIsFetchingItems(false);
    }
  }, [hasFocus, isFetchingItems, itemsEffectKey]);

  /** On focus out, clear selected value and refetch default options (reset state) */
  const handleBlur = () => {
    setDebouncedValue('');
    setHasFocus(false);
  };

  const handleFocus = () => {
    setHasFocus(true);
  };

  return (
    <Select
      {...props}
      {...selectProps}
      controlRef={controlRef}
      name={name}
      onBlur={e => handleBlur(e)}
      onFocus={() => handleFocus()}
      // disabled={loading}
      options={mappedOptions}
      value={typeof value === 'string' ? [{ id: value }] : value}
      onChange={params => {
        onChange(params.value);
      }}
      getOptionLabel={getLabel}
      getValueLabel={getLabel}
      backspaceClearsInputValue={false}
      filterOutSelected
      filterOptions={null}
      onInputChange={event => setDebouncedValue(event.target.value)}
      size={isCompact ? SIZE.compact : SIZE.default}
      overrides={{
        ...selectProps.overrides,
        Dropdown: VirtualDropdown,
      }}
    />
  );
}

EntitySelectFilter.propTypes = {
  name: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  value: propTypes.oneOfType([propTypes.array, propTypes.string]),
  data: propTypes.shape({
    source: propTypes.object.isRequired,
    transformItem: propTypes.func.isRequired,
    getLabel: propTypes.func,
  }).isRequired,
  onChange: propTypes.func.isRequired,
  isCompact: propTypes.bool,
};

EntitySelectFilter.defaultProps = {
  value: [],
  isCompact: false,
};

export default EntitySelectFilter;
