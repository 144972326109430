import _ from 'lodash';
import propTypes from 'prop-types';
import { useEffect } from 'react';

import { defaultFieldPropTypes } from '../../components/fields/Types';

function FormFields({
  fieldKeyPrefix,
  values,
  formik,
  fields,
  onChange,
  isUpdate,
  fieldPayload,
  clearRenderedErrors,
  markRenderedError,
  disabledFields,
}) {
  const formValues = _.cloneDeep(values || formik.values);
  useEffect(() => {
    fields.forEach(({ postChange }) => {
      if (postChange) {
        const result = postChange?.(formik.values);

        if (result) {
          formik.setValues(result);
        }
      }
    });
  }, [JSON.stringify(formik.values)]);

  const callFieldFunction = f =>
    typeof f === 'function'
      ? f(formik.values || values, { isUpdate, ...fieldPayload })
      : f;
  clearRenderedErrors();

  return fields
    .filter(
      ({ isReadOnly, hideInForm, hasFieldAccess }) =>
        !isReadOnly &&
        !callFieldFunction(hideInForm) &&
        (!hasFieldAccess || callFieldFunction(hasFieldAccess)),
    )
    .map(field => {
      const key = `${fieldKeyPrefix}${field.key}`;
      const error = _.get(formik.errors, key);
      markRenderedError(key);

      return (
        <div
          key={field.key}
          style={{ width: '100%', marginBottom: 25, ...field.style }}
        >
          <field.Field
            {...fieldPayload}
            {...field}
            name={key}
            disabled={callFieldFunction(field.disabled) || disabledFields[key]}
            label={callFieldFunction(field.name)}
            config={field.config}
            error={error}
            value={formValues[field.key]}
            parent={formValues}
            onChange={e => {
              let value = e;

              if (e?.target) {
                value = e.target.value;
              }

              if (onChange) {
                onChange(field.key, value);
              } else {
                formik.setFieldValue(key, value);
                formik.setFieldTouched(key);
              }

              const updatedValues = {
                ...formValues,
                __field: key,
              };

              // use _.set for nested validators
              _.set(updatedValues, key, value);

              formik.validateForm(updatedValues);
            }}
            onBlur={formik.handleBlur}
            clearRenderedErrors={clearRenderedErrors}
            markRenderedError={markRenderedError}
          />
        </div>
      );
    });
}

FormFields.propTypes = {
  fields: propTypes.arrayOf(propTypes.shape(defaultFieldPropTypes)).isRequired,
  values: propTypes.object,
  onChange: propTypes.func,
  fieldKeyPrefix: propTypes.string,
  isUpdate: propTypes.bool,
  fieldPayload: propTypes.object,
  clearRenderedErrors: propTypes.func.isRequired,
  markRenderedError: propTypes.func.isRequired,
  disabledFields: propTypes.object,
};

FormFields.defaultProps = {
  values: null,
  onChange: null,
  fieldKeyPrefix: '',
  isUpdate: false,
  fieldPayload: {},
  disabledFields: {},
};

export default FormFields;
