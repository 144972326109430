import getLocalStorageKeyForOverviewLabel from './getLocalStorageKeyForOverviewLabel';

const isTableColumnVisible = (entityKey, overviewLabelKey) => {
  const localStorageKey = getLocalStorageKeyForOverviewLabel(
    entityKey,
    overviewLabelKey,
  );

  const fromLocalStorage = localStorage.getItem(localStorageKey);
  if (!fromLocalStorage) {
    return true; // default
  }

  // we have a value from local storage!
  // since local storage can only save strings... See if we have "true"
  return `${fromLocalStorage}` === 'true';
};

export default isTableColumnVisible;
