import { FULL_ACCESS_ROLES } from '~/config/permissions';

export function hasAnyRole(userRoles, checkRoles) {
  return !!checkRoles?.find(role => userRoles.includes(role));
}

/**
 * Will check if user has full access rights or specific rights to this object.
 * @param {array} to an array of user roles to check against
 * @param {array} userRoles
 * @param {boolean} ignoreFullAccess
 */
export function hasAccess(to, userRoles, ignoreFullAccess = false) {
  return (
    (!ignoreFullAccess && hasAnyRole(userRoles, FULL_ACCESS_ROLES)) ||
    hasAnyRole(userRoles, to)
  );
}
