import { FormControl } from 'baseui/form-control';
import { Select } from 'baseui/select';
import { colors } from 'baseui/tokens';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { useState, useEffect } from 'react';

import FormFields from '@bitsoflove/entity-management/containers/internal/FormFields';

import { defaultFieldDefaultValues, defaultFieldPropTypes } from './Types';

function NestedField({
  name,
  label,
  error,
  value = {},
  data,
  clearRenderedErrors,
  markRenderedError,
  onChange,
}) {
  const { pivotValues, properties } = data;
  const formik = useFormikContext();
  const [activePivotValue, setActivePivotValue] = useState(pivotValues?.[0]);
  const item = value[activePivotValue?.id];

  useEffect(() => {
    if (pivotValues?.length > 0 && !activePivotValue) {
      setActivePivotValue(pivotValues[0]);
    }
  }, [activePivotValue, pivotValues]);

  return (
    <FormControl label={label}>
      <fieldset>
        <legend>
          <Select
            options={pivotValues || []}
            getOptionLabel={({ option }) => {
              if (error?.[option.id]) {
                return (
                  <bold style={{ color: colors.red400 }}>{option.label}</bold>
                );
              }

              return option.label;
            }}
            clearable={false}
            value={[activePivotValue || {}]}
            placeholder=""
            onChange={options => setActivePivotValue(options.option)}
            error={error}
          />
        </legend>

        <FormFields
          formik={formik}
          fieldKeyPrefix={`${name}.${activePivotValue?.id}.`}
          fields={properties}
          values={item}
          onChange={(key, e) => {
            const newValue = { ...value };
            _.set(newValue, `${activePivotValue?.id}.${key}`, e);
            onChange(newValue);
          }}
          clearRenderedErrors={clearRenderedErrors}
          markRenderedError={markRenderedError}
        />
      </fieldset>
    </FormControl>
  );
}

NestedField.propTypes = {
  ...defaultFieldPropTypes,
};

NestedField.defaultProps = {
  ...defaultFieldDefaultValues,
  value: {},
};

export default NestedField;
