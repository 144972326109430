import { KIND, Tag as TagComponent, VARIANT } from 'baseui/tag';
import propTypes from 'prop-types';

const Tag = ({ label, color, isInline, ...props }) => (
  <TagComponent
    kind={KIND.custom}
    closeable={false}
    variant={VARIANT.solid}
    color={color}
    {...props}
    overrides={{
      Root: {
        style: {
          maxWidth: 'unset',
          ...(isInline
            ? {
                marginTop: 0,
                marginBottom: 0,
              }
            : {}),
        },
      },
      Text: {
        style: {
          maxWidth: 'unset',
        },
      },
    }}
  >
    {label}
  </TagComponent>
);

Tag.propTypes = {
  color: propTypes.string,
  label: propTypes.string.isRequired,
  isInline: propTypes.bool,
};

Tag.defaultProps = {
  color: '#333',
  isInline: true,
};

export default Tag;
