import { theme } from '@bitsoflove/pattyn-360/lib/styles';
import { createTheme } from 'baseui';
import getConfig from 'next/config';
import { shade, tint } from 'polished';

const { publicRuntimeConfig = {} } = getConfig() || {};
const { brand, brandMediumTinted } = theme.colors;

export default {
  ...theme,
  staticDir: publicRuntimeConfig.STATIC_DIR,
  bolFooterBg: '#000',
  bolFooterColor: '#a0a6ad',
  bolFooterGraphColor: '#007bff',
};

const primitives = {
  accent: brandMediumTinted,
  accent50: tint(0.9, brand),
  accent100: tint(0.8, brand),
  accent200: tint(0.4, brand),
  accent300: tint(0.2, brand),
  accent400: brand,
  accent500: shade(0.2, brand),
  accent600: shade(0.4, brand),
  accent700: shade(0.8, brand),
};

export const BaseTheme = createTheme(primitives, {
  borders: {
    buttonBorderRadius: '5px',
  },
  colors: {
    buttonPrimaryFill: primitives.accent400,
    buttonPrimaryHover: primitives.accent300,
    buttonPrimaryActive: primitives.accent200,
  },
});
