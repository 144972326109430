import { FormControl } from 'baseui/form-control';
import { Checkbox as CheckboxInput, LABEL_PLACEMENT } from 'baseui/checkbox';

import { defaultFieldDefaultValues, defaultFieldPropTypes } from './Types';
import ErrorMessage from './ErrorMessage';

function Checkbox({ name, label, error, value, onChange, type, ...props }) {
  return (
    <FormControl error={<ErrorMessage error={error} />}>
      <CheckboxInput
        checked={value}
        labelPlacement={LABEL_PLACEMENT.right}
        name={name}
        error={error}
        {...props}
        onChange={e => onChange(e.target.checked)}
      >
        {label}
      </CheckboxInput>
    </FormControl>
  );
}

Checkbox.propTypes = {
  ...defaultFieldPropTypes,
};

Checkbox.defaultProps = {
  ...defaultFieldDefaultValues,
};

export default Checkbox;
