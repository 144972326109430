export default function handleSubmitError({
  fallback,
  setFieldError,
  error,
  setSubmitting,
}) {
  const errors = error.graphQLErrors || error.response?.errors;
  setFieldError('submit', {
    message: errors?.[0]?.message || fallback,
    error,
  });
  setSubmitting(false);
}
