import propTypes from 'prop-types';
import { createContext, useContext, useState } from 'react';

import { Text as TextFilter } from '@bitsoflove/entity-management/filters';

// create a context that will hold a [value, setValue] array
// this is basically what setState() returns
// so we are going to use the setState hook in order to save the state in the context
// this will make sure our filter value is preserved across page navigations
export const SearchFilterContext = createContext([]);
SearchFilterContext.displayName = 'SearchFilterContext';

// note that this provider will wrap the entire app, as defined in _app.js
export const SearchFilterProvider = ({ children }) => {
  const filterState = useState('');

  return (
    <SearchFilterContext.Provider value={filterState}>
      {children}
    </SearchFilterContext.Provider>
  );
};

SearchFilterProvider.propTypes = {
  children: propTypes.node.isRequired,
};

const TextSearchFilter = props => {
  const { value, onChange } = props;
  const [, setValue] = useContext(SearchFilterContext);

  return (
    <TextFilter
      {...props}
      value={value || null}
      onChange={newValue => {
        setValue(newValue);
        onChange(newValue);
      }}
    />
  );
};

TextSearchFilter.propTypes = {
  value: propTypes.string,
  onChange: propTypes.func.isRequired,
};

TextSearchFilter.defaultProps = {
  value: null,
};

export default {
  key: 'query',
  name: 'Search',
  Filter: TextSearchFilter,
  placeholder: 'Search',
};
