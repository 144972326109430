import { useEffect } from 'react';
import { useAsyncFn } from 'react-use';
import { warn } from '../utils/console';
import useAdapterHook from './useAdapterHook';

const useGetAdapter = (entity, options) => {
  const { adapterHookConfig, autoFetch = true, initialFilters } = options || {};
  const hooks = useAdapterHook(entity.key, 'get', adapterHookConfig);
  const adapter = entity.adapters?.get;

  const [{ value, loading, error }, fetch] = useAsyncFn(
    async fetchOptions => {
      let modifiedOptions = fetchOptions;
      if (hooks?.pre) {
        modifiedOptions = hooks.pre({ entity, options: fetchOptions });
      }

      const result = await adapter(modifiedOptions);

      let modifiedResult = result;
      if (hooks?.post) {
        modifiedResult = hooks.post({
          entity,
          result,
          options: fetchOptions,
        });
      }

      return modifiedResult;
    },
    [adapter],
  );
  const [resultItems, resultMeta] = value || [];

  useEffect(() => {
    if (autoFetch) {
      fetch({ filters: initialFilters || undefined });
    }
  }, []);

  if (!adapter) {
    const err = `No 'get' adapter configured for ${entity.name}`;
    warn(err);
    return {
      meta: {
        error: err,
      },
    };
  }

  return {
    items: resultItems,
    meta: {
      loading,
      error,
      ...resultMeta,
    },
    refetch: refetchOptions => fetch(refetchOptions),
  };
};

export default useGetAdapter;
