import { Input } from 'baseui/input';
import { FormControl } from 'baseui/form-control';

import { defaultFieldDefaultValues, defaultFieldPropTypes } from './Types';
import ErrorMessage from './ErrorMessage';

function TextField({ name, type = 'text', label, error, value, ...props }) {
  return (
    <FormControl label={label} error={<ErrorMessage error={error} />}>
      <Input
        className="text fullwidth"
        name={name}
        type={type}
        error={error}
        value={value || ''}
        {...props}
      />
    </FormControl>
  );
}

TextField.propTypes = {
  ...defaultFieldPropTypes,
};

TextField.defaultProps = {
  ...defaultFieldDefaultValues,
};

export default TextField;
