import propTypes from 'prop-types';

import EntityContext from '../../Context/EntityContext';
import FormWrapper from '../../components/FormWrapper';
import handleSubmitError from '../../utils/handleSubmitError';
import Form from '../internal/Form';

function EntityCreateForm({
  entity,
  defaultValues,
  disabledFields,
  children,
  onSuccess,
  fieldPayload,
}) {
  return (
    <FormWrapper>
      <EntityContext.Provider value={entity}>
        <Form
          initialValues={{
            ...entity?.properties?.reduce(
              (all, field) => ({
                ...all,
                [field.key]:
                  defaultValues[field.key] ?? field.initialValue ?? undefined,
              }),
              {},
            ),
          }}
          onSubmit={async (
            values,
            { setSubmitting, setFieldError, resetForm },
          ) => {
            try {
              setFieldError('submit', null);
              setSubmitting(true);

              const result = await entity?.adapters?.create({
                values,
              });

              onSuccess(result);
              resetForm({});
            } catch (error) {
              handleSubmitError({
                fallback: 'Unable to create entity',
                setSubmitting,
                setFieldError,
                error,
              });
            }
          }}
          fieldPayload={fieldPayload}
          disabledFields={disabledFields}
        >
          {children}
        </Form>
      </EntityContext.Provider>
    </FormWrapper>
  );
}

EntityCreateForm.propTypes = {
  entity: propTypes.object.isRequired,
  children: propTypes.node,
  onSuccess: propTypes.func.isRequired,
  defaultValues: propTypes.shape({}),
  fieldPayload: propTypes.object,
  disabledFields: propTypes.object,
};

EntityCreateForm.defaultProps = {
  children: null,
  defaultValues: {},
  fieldPayload: null,
  disabledFields: {},
};

export default EntityCreateForm;
