import propTypes from 'prop-types';
import { StyledSpinnerNext } from 'baseui/spinner';
import useGetAdapter from '@bitsoflove/entity-management/hooks/useGetAdapter';
import RequestError from '@bitsoflove/entity-management/components/RequestError';

import useFilters from '@bitsoflove/entity-management/hooks/useFilter';
import usePagination from '@bitsoflove/entity-management/hooks/usePagination';
import PickerTable from '../internal/PickerTable';
import EntityContext from '../../Context/EntityContext';
import FilterBar from '../internal/FilterBar';

function EntityPicker({ entity, adapterHookConfig, onPick }) {
  const { items, meta, refetch } = useGetAdapter(entity, {
    adapterHookConfig,
  });
  const { loading, error } = meta;

  function handlePick(payload) {
    onPick({ ...payload, entityType: entity.className });
  }

  const [filters, handleFilterChange] = useFilters({
    handleChange: props => {
      if (!loading) {
        refetch(props);
      }
    },
  });

  const [pagination] = usePagination({
    meta,
    handleChange: props => {
      handleFilterChange({
        ...filters,
        ...props,
      });
    },
  });

  if (error) {
    return (
      <RequestError
        message="Something went wrong."
        error={error}
        refetch={refetch}
        loading={loading}
      />
    );
  }

  return (
    <EntityContext.Provider value={entity}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'stretch',
          height: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <FilterBar
            adapterHookConfig={adapterHookConfig}
            onChange={handleFilterChange}
          />
        </div>

        {loading && !items && <StyledSpinnerNext />}
        {pagination}
        <PickerTable
          items={items}
          onPick={handlePick}
          // onPick={onPick}
          isLoading={loading && items}
          handleSortChange={({ orderBy, orderDirection }) => {
            handleFilterChange({
              ...filters,
              orderBy,
              orderDirection,
            });
          }}
        />
        {pagination}
      </div>
    </EntityContext.Provider>
  );
}

EntityPicker.propTypes = {
  entity: propTypes.object.isRequired,
  onPick: propTypes.func.isRequired,
  adapterHookConfig: propTypes.shape({
    get: propTypes.shape({
      pre: propTypes.func,
      post: propTypes.func,
    }),
  }),
};
EntityPicker.defaultProps = {
  adapterHookConfig: null,
};

export default EntityPicker;
