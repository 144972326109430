import { GraphQLClient } from 'graphql-request';

import { API_URL } from '../config';

const client = new GraphQLClient(API_URL);

/**
 * Call with `null` to remove the Authorization header.
 * @param {String|null} token
 */
export function setClientToken(token) {
  client.setHeader('Authorization', token ? `Bearer ${token}` : null);
}

export default client;
