import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

const DEFAULT_CONFIRM_MESSAGE =
  'Reload site? Changes that you made may not be saved.';

const handleWindowClose = message => e => {
  e.preventDefault();
  e.returnValue = message;
  return message;
};

const useLeaveConfirmation = ({
  isEnabled = true,
  confirmMessage = DEFAULT_CONFIRM_MESSAGE,
} = {}) => {
  const router = useRouter();
  const leaveConfirmed = useRef(false);

  // Use beforeunload to prevent closing the tab, refreshing the page or moving outside the Next app
  useEffect(() => {
    if (isEnabled) {
      const onBeforeUnload = handleWindowClose(confirmMessage);
      window.addEventListener('beforeunload', onBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', onBeforeUnload);
      };
    }
  }, [isEnabled]);

  // Use routeChangeStart to prevent navigation inside of the Next app
  // Uses a module variable to bypass the confirm, otherwise we would be in a loop
  useEffect(() => {
    if (isEnabled) {
      const onRouteChangeStart = () => {
        if (leaveConfirmed.current) return;

        if (window.confirm(confirmMessage)) leaveConfirmed.current = true;
        else {
          router.events.emit('routeChangeError');
          throw 'routeChange aborted.';
        }
      };
      router.events.on('routeChangeStart', onRouteChangeStart);

      return () => router.events.off('routeChangeStart', onRouteChangeStart);
    }
  }, [leaveConfirmed.current, isEnabled]);
};

export default useLeaveConfirmation;
