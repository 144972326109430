import { Global } from '@emotion/core';
import styled from '@emotion/styled';

import { ENVIRONMENT, PRODUCTION_URL } from '~/config';

const Warning = styled.div`
  font-family: $headings-font-family;
  line-height: 36px;

  position: fixed;
  z-index: 20000;
  top: 0;
  left: 0;

  width: 100%;
  height: 36px;

  text-align: center;

  color: #fff;
  background-color: #dc3545;

  a {
    text-decoration: underline;

    color: #fff;

    &:hover {
      text-decoration: none;
    }
  }
`;

const EnvironmentWarning = () => {
  if (ENVIRONMENT !== 'acceptance') {
    return null;
  }

  return (
    <>
      <Global
        styles={{
          '#__next': {
            marginTop: '36px',
          },
        }}
      />
      <Warning>
        Warning, this is not the production CMS environment.{' '}
        {PRODUCTION_URL && (
          <>
            Lost? Go to <a href={PRODUCTION_URL}>{PRODUCTION_URL}</a>
          </>
        )}
      </Warning>
    </>
  );
};

export default EnvironmentWarning;
