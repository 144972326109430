import { FormControl } from 'baseui/form-control';
import { Select } from 'baseui/select';
import propTypes from 'prop-types';

import ErrorMessage from '../ErrorMessage';
import { VirtualDropdown } from './FixedSize';

function SelectField({ name, label, onChange, value, data, error, ...props }) {
  return (
    <FormControl label={label} error={<ErrorMessage error={error} />}>
      <Select
        {...data}
        filterOutSelected
        backspaceClearsInputValue={false}
        overrides={{ Dropdown: VirtualDropdown }}
        valueKey="label"
        name={name}
        error={error}
        value={
          typeof value === 'string'
            ? [data.options?.find(({ id }) => id === value) || { id: value }]
            : value
        }
        onChange={params => {
          onChange(params.value);
        }}
        {...props}
      />
    </FormControl>
  );
}

SelectField.propTypes = {
  error: propTypes.object,
  name: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  value: propTypes.oneOfType([propTypes.array, propTypes.string]),
  data: propTypes.shape({
    options: propTypes.arrayOf(
      propTypes.shape({
        id: propTypes.string,
        label: propTypes.string,
      }),
    ).isRequired,
  }).isRequired,
  onChange: propTypes.func.isRequired,
};

SelectField.defaultProps = {
  error: null,
  value: [],
};

export default SelectField;
