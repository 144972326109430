import { ApolloProvider } from '@apollo/client';
import { Global } from '@emotion/core';
import { BaseProvider } from 'baseui';
import { SnackbarProvider } from 'baseui/snackbar';
import { ThemeProvider, useTheme } from 'emotion-theming';
import { NextSeo } from 'next-seo';
import App from 'next/app';
import Head from 'next/head';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider as StyletronProvider } from 'styletron-react';

import AuthProvider from '~/api/AuthProvider';
import client from '~/api/apollo.client';
import EnvironmentWarning from '~/components/EnvironmentWarning';
import RouterLoader from '~/components/RouterLoader';
import AUTH_CONFIG from '~/config/auth';
import { TagFilterProvider } from '~/config/entities/utils/TagFilterConfig';
import { SearchFilterProvider } from '~/config/entities/utils/textSearchFilterConfig';

import globalStyles from '../styles/global';
import theme, { BaseTheme } from '../styles/theme';
import { styletron } from '../styletron';
import env from '../utils/env';
import { GTMPageTracker } from '../utils/gtag';

import 'react-sortable-tree/style.css';

const GlobalStyles = () => {
  const value = useTheme();
  return (
    <div>
      <Global styles={globalStyles({ theme: value })} />
    </div>
  );
};

export default class MyApp extends App {
  static async getInitialProps(appContext) {
    const appProps = await App.getInitialProps(appContext);
    return { ...appProps };
  }

  render() {
    const { Component, pageProps } = this.props;
    const { isAuth, isPublic } = Component?.options || {};

    return (
      <DndProvider backend={HTML5Backend}>
        <Head>
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
        </Head>

        <NextSeo
          title="Pattyn 360 - CMS"
          noindex={!env('production')}
          nofollow={!env('production')}
        />
        <ApolloProvider client={client}>
          <GTMPageTracker>
            <ThemeProvider theme={theme}>
              <EnvironmentWarning />
              <StyletronProvider value={styletron}>
                <BaseProvider theme={BaseTheme}>
                  <SearchFilterProvider>
                    <TagFilterProvider>
                      <SnackbarProvider>
                        <GlobalStyles />
                        <RouterLoader />
                        {/* <Global styles={globalStyles({ theme })} /> */}
                        <AuthProvider
                          {...AUTH_CONFIG}
                          isAuth={isAuth}
                          isPublic={isPublic}
                        >
                          <Component {...pageProps} />
                        </AuthProvider>
                      </SnackbarProvider>
                    </TagFilterProvider>
                  </SearchFilterProvider>
                </BaseProvider>
              </StyletronProvider>
            </ThemeProvider>
          </GTMPageTracker>
        </ApolloProvider>
      </DndProvider>
    );
  }
}
