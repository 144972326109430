import { StyledSpinnerNext } from 'baseui/spinner';
import propTypes from 'prop-types';

import OverviewActionBar from '@bitsoflove/entity-management/components/OverviewActionBar';
import RequestError from '@bitsoflove/entity-management/components/RequestError';
import useFilters from '@bitsoflove/entity-management/hooks/useFilter';
import usePagination from '@bitsoflove/entity-management/hooks/usePagination';

import EntityContext from '../../Context/EntityContext';
import useGetAdapter from '../../hooks/useGetAdapter';
import { useGlobalFilterContext } from '../../hooks/useGlobalFilterContext';
import Table from '../internal/Table';

function EntityList({
  entity,
  onCreateRouted,
  onUpdateRouted,
  onSelectionAllChange,
  onSelectionChange,
  selection,
}) {
  const globalFilterContext = useGlobalFilterContext();
  const { items, meta, refetch } = useGetAdapter(entity, {
    initialFilters: globalFilterContext,
  });
  const { loading, error } = meta;

  const [filters, handleFilterChange] = useFilters({
    handleChange: props => {
      refetch(props);
    },
  });

  const [pagination] = usePagination({
    meta,
    handleChange: props => {
      handleFilterChange({
        ...filters,
        ...props,
      });
    },
  });

  if (error) {
    return (
      <RequestError
        message="Something went wrong."
        error={error}
        refetch={refetch}
        loading={loading}
      />
    );
  }

  const isLoading = loading && !items;

  return (
    <EntityContext.Provider value={entity}>
      <OverviewActionBar
        onFilterChange={handleFilterChange}
        onCreateRouted={onCreateRouted}
        isLoading={isLoading}
      />
      {isLoading && <StyledSpinnerNext />}

      {pagination}

      <Table
        items={items}
        isLoading={isLoading}
        onUpdate={onUpdateRouted}
        onDelete={() => refetch({ filters })}
        handleSortChange={({ orderBy, orderDirection }) => {
          handleFilterChange({
            ...filters,
            orderBy,
            orderDirection,
          });
        }}
        onSelectionAllChange={onSelectionAllChange}
        onSelectionChange={onSelectionChange}
        selection={selection}
      />
    </EntityContext.Provider>
  );
}

EntityList.propTypes = {
  entity: propTypes.object.isRequired,
  onCreateRouted: propTypes.func.isRequired,
  onUpdateRouted: propTypes.func.isRequired,
  onSelectionAllChange: propTypes.func,
  onSelectionChange: propTypes.func,
  selection: propTypes.array,
};

EntityList.defaultProps = {
  onSelectionAllChange: null,
  onSelectionChange: null,
  selection: [],
};

export default EntityList;
