// Fixed size list for performance,
// does not make a visual difference, but there is less lag when opening the list initially.
// https://baseweb.design/components/select/#select-with-many-options

import { withStyle } from 'baseui';
import { StyledList, StyledEmptyState } from 'baseui/menu';
import { StyledDropdownListItem } from 'baseui/select';
import propTypes from 'prop-types';
import { Children, forwardRef } from 'react';
import { FixedSizeList } from 'react-window';

const LIST_ITEM_HEIGHT = 40;
const EMPTY_LIST_HEIGHT = 84;
const ListItem = withStyle(StyledDropdownListItem, {
  paddingTop: 0,
  paddingBottom: 0,
  display: 'flex',
  alignItems: 'center',
});

export const FixedSizeListItem = ({ data, index, style }) => {
  const {
    item,
    overrides,
    getItemLabel,
    resetMenu,
    renderHrefAsAnchor,
    renderAll,
    flexGridItemIndex,
    flexGridRowGap,
    flexGridColumnGap,
    flexGridColumnCount,
    ...restChildProps
  } = data[index].props;

  return (
    <ListItem
      key={item.id}
      style={{
        boxSizing: 'border-box',
        ...style,
      }}
      {...restChildProps}
    >
      {getItemLabel ? getItemLabel(item) : item.label}
    </ListItem>
  );
};

FixedSizeListItem.propTypes = {
  index: propTypes.number.isRequired,
  style: propTypes.object,
  data: propTypes.arrayOf(
    propTypes.shape({
      props: propTypes.shape({
        item: propTypes.object,
        overrides: propTypes.object,
        getItemLabel: propTypes.func,
      }),
    }),
  ).isRequired,
};
FixedSizeListItem.defaultProps = {
  style: {},
};

const DropdownList = ({ children: propsChildren, $maxHeight }, ref) => {
  const children = Children.toArray(propsChildren);
  if (!children[0] || !children[0].props.item) {
    return (
      <StyledList $style={{ height: `${EMPTY_LIST_HEIGHT}px` }} ref={ref}>
        <StyledEmptyState {...children[0].props} />
      </StyledList>
    );
  }
  const height = Math.min(
    350,
    parseInt($maxHeight, 10),
    children.length * LIST_ITEM_HEIGHT,
  );

  return (
    <StyledList
      $style={{
        height: `${height}px`,
        paddingLeft: 0,
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
      }}
      ref={ref}
    >
      <FixedSizeList
        width="100%"
        height={height}
        itemCount={children.length}
        itemData={children}
        itemKey={(index, data) => data[index].props.item.id}
        itemSize={LIST_ITEM_HEIGHT}
      >
        {FixedSizeListItem}
      </FixedSizeList>
    </StyledList>
  );
};

export const VirtualDropdown = forwardRef(DropdownList);

VirtualDropdown.propTypes = {
  children: propTypes.node.isRequired,
  $maxHeight: propTypes.oneOfType([propTypes.string, propTypes.number]),
};
VirtualDropdown.defaultProps = {
  $maxHeight: 350,
};
