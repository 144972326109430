import { format, isValid, parseISO } from 'date-fns';
import propTypes from 'prop-types';

const DateTime = ({ datetime }) => {
  const parsed = parseISO(datetime);

  if (!isValid(parsed)) {
    return <span>-</span>;
  }

  const date = format(parsed, 'dd/MM/yyyy');
  const time = format(parsed, 'HH:mm');

  return (
    <span>
      {date}
      <br />
      {time}
    </span>
  );
};

DateTime.propTypes = {
  datetime: propTypes.string,
};

DateTime.defaultProps = {
  datetime: null,
};

export default DateTime;
