export const DEFAULT_LOCALE = 'en';

export const nestedLocalesDefaultValue = { [DEFAULT_LOCALE]: {} };

export const locales = [
  {
    id: 'en',
    label: 'en',
  },
  {
    id: 'fr',
    label: 'fr',
  },
  {
    id: 'es',
    label: 'es',
  },
  { id: 'de', label: 'de' },
  { id: 'pl', label: 'pl' },
  { id: 'it', label: 'it' },
  { id: 'nl-BE', label: 'nl' },
];

export const translatableLocales = locales.filter(
  locale => locale.id !== DEFAULT_LOCALE,
);

export default locales;
