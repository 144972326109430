import { Drawer, SIZE } from 'baseui/drawer';

import propTypes from 'prop-types';
import { EntityCreateForm } from '@bitsoflove/entity-management/containers';

function Creatable({ entity, values, onCreate, onCancel }) {
  return (
    <Drawer isOpen autoFocus onClose={onCancel} size={SIZE.auto}>
      <EntityCreateForm
        entity={entity}
        initialValues={values}
        onSuccess={onCreate}
      />
    </Drawer>
  );
}

Creatable.propTypes = {
  entity: propTypes.object.isRequired,
  values: propTypes.object.isRequired,
  onCreate: propTypes.func.isRequired,
  onCancel: propTypes.func.isRequired,
};

Creatable.defaultProps = {};

export default Creatable;
