import DinBoldTtf from '@bitsoflove/pattyn-360/lib/assets/fonts/din-bold-webfont.ttf';
import DinBoldWoff from '@bitsoflove/pattyn-360/lib/assets/fonts/din-bold-webfont.woff';
import DinMediumTtf from '@bitsoflove/pattyn-360/lib/assets/fonts/din-medium-webfont.ttf';
import DinMediumWoff from '@bitsoflove/pattyn-360/lib/assets/fonts/din-medium-webfont.woff';
import DinTtf from '@bitsoflove/pattyn-360/lib/assets/fonts/din-regular-webfont.ttf';
import DinWoff from '@bitsoflove/pattyn-360/lib/assets/fonts/din-regular-webfont.woff';
import { css } from '@emotion/core';

export default css`
  @font-face {
    font-family: 'DIN';
    src: url(${DinBoldTtf}) format('truetype'),
      url(${DinBoldWoff}) format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'DIN';
    src: url(${DinMediumTtf}) format('truetype'),
      url(${DinMediumWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'DIN';
    src: url(${DinTtf}) format('truetype'), url(${DinWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
`;
