import propTypes from 'prop-types';
import { Button, KIND, SIZE } from 'baseui/button';
import { Check } from 'baseui/icon';
import Table from './Table';

const PickerTable = ({ onPick, ...props }) => (
  <Table
    {...props}
    overrides={{
      ActionsHeaderCell: {
        props: {
          style: { maxWidth: 100, minWidth: 100 },
        },
      },
      ActionsCell: {
        props: {
          style: { maxWidth: 100, minWidth: 100 },
        },
      },
    }}
    actions={item => (
      <Button
        type="button"
        startEnhancer={() => <Check size={24} />}
        kind={KIND.minimal}
        size={SIZE.compact}
        overrides={{
          StartEnhancer: {
            style: { marginRight: 0 },
          },
        }}
        onClick={() => onPick(item)}
      >
        pick
      </Button>
    )}
  />
);

PickerTable.propTypes = {
  onPick: propTypes.func.isRequired,
};

export default PickerTable;
