import { StyledSpinnerNext } from 'baseui/spinner';
import { styled } from 'styletron-react';

const StyledContainer = styled('div', () => ({
  position: 'fixed',
  top: '10px',
  left: '10px',
  color: 'white',
  zIndex: 1000,
}));

const GlobalLoader = () => {
  return (
    <StyledContainer>
      <StyledSpinnerNext />
    </StyledContainer>
  );
};

GlobalLoader.propTypes = {};

export default GlobalLoader;
