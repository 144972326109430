const transformAPIValues = values => ({
  ...values,
  translations: values.translations?.reduce(
    (translations, { locale, ...translationValues }) => ({
      ...translations,
      [locale]: translationValues,
    }),
    {},
  ),
});

export default transformAPIValues;
