import { Textarea } from 'baseui/textarea';
import { FormControl } from 'baseui/form-control';

import { defaultFieldDefaultValues, defaultFieldPropTypes } from './Types';
import ErrorMessage from './ErrorMessage';

function TextAreaField({ name, label, error, value, ...props }) {
  return (
    <FormControl label={label} error={<ErrorMessage error={error} />}>
      <Textarea
        className="text fullwidth"
        name={name}
        error={error}
        value={value || ''}
        {...props}
      />
    </FormControl>
  );
}

TextAreaField.propTypes = {
  ...defaultFieldPropTypes,
};

TextAreaField.defaultProps = {
  ...defaultFieldDefaultValues,
};

export default TextAreaField;
